import styled from "styled-components";
import theme from "../../../theme";

export const TokenPriceContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
`;

export const TokenTitle = styled.h1`
  font-size: 13px;
`;

export const Price = styled.h1`
  font-size: 15px;
  color: white;
  margin: 5px;
`;

export const PriceTitle = styled.h1`
  font-size: 25px;
  margin: 0;
  background: -webkit-linear-gradient(
    ${theme.palette.secondary.green},
    ${theme.palette.secondary.blue}
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;
