import {useCallback} from "react";
import {useEffect, useState} from "react";
import {IUsers} from "./types";
import axios from "../../services/axios";
import {useNotify} from "../Notify";
import useNotifications from '../../services/notifications/useNotifications';

interface UserQuery {
    search: string,
    filter: string,
}

const useUsers = () => {
    const [users, setUsers] = useState<IUsers[]>([]);
    const [page, setPage] = useState<number>(1)
    const [pageCount, setPageCount] = useState<number>(0)
    const [loading, setLoading] = useState<boolean>(false)
    const [buttonLoading, setButtonLoading] = useState<boolean>(false)
    const [search, setSearch] = useState<string>('');
    const {notification} = useNotify();
    const {createNotification} = useNotifications();

    const getUsers = async () => {
        try {
            setLoading(true);
            let res
            if (search) {
                res = await axios.get(`/users?page=${page}&search=${search}`);
            } else {
                res = await axios.get(`/users?page=${page}`);
            }
            if (res.data) {
                setUsers(res.data.items);
                setPageCount(res.data.pageCount);
                setPage(res.data.page);
            } else {
                setUsers([]);
            }
            setLoading(false);
        } catch (error) {
            console.log(error);
            setLoading(false)
        }
    };

    const getBalance = useCallback(async (user: IUsers) => {
        const res = await axios.get(`/users/${user._id}/balance`);
        return res.data
    }, [])

    const approveUser = useCallback(async (user: IUsers) => {
            try {
                setButtonLoading(true)
                notification('approveUser', 'hint', 'Aprobando usuario...')
                await axios.patch(`/users/${user._id}/validate`);
                await createNotification({
                    body: 'Tu usuario fue validado correctamente',
                    title: 'Identidad validada',
                    navigateTo: {
                        stack: 'Perfil',
                        screen: 'ProfileHome',
                    },
                    tokens: [user?.FCM_TOKEN!],
                    profile: user?._id,
                });
                await getUsers();
                setButtonLoading(false);
                setUsers(users.map((u: IUsers) => {
                    if (u._id === user._id) {
                        u.validated = true;
                    }
                    return u
                }))
                notification('approveUser', 'success', 'Usuario aprobado exitosamente.')
            } catch (error) {
                setButtonLoading(false);
                notification('approveUser', 'error', (error as Error).message)
            }
        },
        [getUsers, notification]
    );

    useEffect(() => {
        getUsers();
    }, [page]);

    return {
        users,
        getUsers,
        approveUser,
        page,
        setPage,
        getBalance,
        pageCount,
        loading,
        buttonLoading,
        search,
        setSearch
    };
};

export default useUsers; 
 