import { ThemeProvider } from "@mui/material";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import theme from "../../theme";
import Auth from "../Auth/Auth";
import Auditors from "../Auditors/Auditors";
import NFTRequests from "../NFTRequests/NFTRequests";
import Layout from "../Layout";
import PrivateRoute from "./PrivateRoute";
import AuthProvider from "../Auth/AuthProvider";
import Users from "../Users/Users";
import Home from "../Layout/Home";
import Events from "../Events/Events";
import NftRequestDetail from "../NFTRequests/NftRequestDetail";
import { Configuration } from "../Configuration";
import Bonds from "../Bonds";
import AbsorptionRates from "../AbsorptionRates";

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <AuthProvider>
        <BrowserRouter>
          <Routes>
            <Route element={<Layout />}>
              <Route path="/login" element={<Auth />} />
              <Route
                path="/"
                element={
                  <PrivateRoute>
                    <Home />
                  </PrivateRoute>
                }
              />
              <Route
                path="/auditors"
                element={
                  <PrivateRoute>
                    <Auditors />
                  </PrivateRoute>
                }
              />
              <Route
                path="/bonds"
                element={
                  <PrivateRoute>
                    <Bonds />
                  </PrivateRoute>
                }
              />
              <Route
                path="/requests"
                element={
                  <PrivateRoute>
                    <NFTRequests />
                  </PrivateRoute>
                }
              />
              <Route
                path="/requests/:id"
                element={
                  <PrivateRoute>
                    <NftRequestDetail />
                  </PrivateRoute>
                }
              />
              <Route
                path="/users"
                element={
                  <PrivateRoute>
                    <Users />
                  </PrivateRoute>
                }
              />
              <Route
                path="/absorption_rates"
                element={
                  <PrivateRoute>
                    <AbsorptionRates />
                  </PrivateRoute>
                }
              />

              <Route
                path="/events"
                element={
                  <PrivateRoute>
                    <Events />
                  </PrivateRoute>
                }
              />
              <Route
                path="/configuration"
                element={
                  <PrivateRoute>
                    <Configuration />
                  </PrivateRoute>
                }
              />
            </Route>
          </Routes>
        </BrowserRouter>
      </AuthProvider>
    </ThemeProvider>
  );
};

export default App;
