import { useCallback, useState, useEffect } from "react";
import { ethers } from "ethers";
import {
  Auditors,
  Auditors__factory,
  Orderbook,
  Orderbook__factory,
  TCOE,
  TCOE__factory,
  DAI,
  DAI__factory,
  BiotokenFactory,
  BiotokenFactory__factory,
} from "../../contracts/typechain";
import useAuth from "../Auth/useAuth";
import { useNotify } from "../Notify";
import env from "../../env";

const getContract = <T extends ethers.Contract>(
  address: string,
  factory: ethers.ContractInterface,
  signer?: ethers.Signer | ethers.providers.Provider
) => {
  return new ethers.Contract(address, factory, signer) as T;
};

const useWallet = () => {
  const { signer, logout } = useAuth() || {};
  const { notification } = useNotify();
  const [contractsStatus, setContractsStatus] = useState({
    nftRequestPaused: false,
    orderbookPaused: false,
  });

  const tcoeContract = getContract<TCOE>(
    env.TCOE_ADDRESS,
    TCOE__factory.abi,
    signer
  );
  const orderBookContract = getContract<Orderbook>(
    env.ORDERBOOK_ADDRESS,
    Orderbook__factory.abi,
    signer
  );
  const auditorsContract = getContract<Auditors>(
    env.BIOTOKEN_FACTORY_ADDRESS,
    Auditors__factory.abi,
    signer
  );
  const daiContract = getContract<DAI>(
    env.ERC20_ADDRESS,
    DAI__factory.abi,
    signer
  );
  const nftRequestContract = getContract<BiotokenFactory>(
    env.BIOTOKEN_FACTORY_ADDRESS,
    BiotokenFactory__factory.abi,
    signer
  );

  const getLoggedAddress = useCallback(async () => {
    return await signer?.getAddress();
  }, [signer]);

  const getContractsStatus = async () => {
    const nftRequestPaused = await nftRequestContract.paused();
    const orderbookPaused = await orderBookContract.paused();
    return setContractsStatus({ nftRequestPaused, orderbookPaused });
  };
  const handlelogout = () => {
    console.log("entra aca");
    logout && logout();
  };
  const updateOwnerAddress = useCallback(async () => {
    try {
      const newOwner = prompt("Ingrese address del nuevo owner");
      if (newOwner) {
        notification("updateOwnerAddress", "hint", "Modificando owner...");
        const tx = await auditorsContract.transferOwnership(newOwner);
        notification("updateOwnerAddress", "hint", "Modificando owner...");
        await tx.wait();
        notification(
          "updateOwnerAddress",
          "success",
          "Owner modificado correctamente."
        );

        handlelogout();
      }
    } catch (error) {
      notification("updateOwnerAddress", "error", (error as Error).message);
    }
  }, []);

  const pauseContracts = async () => {
    try {
      const confirm = window.confirm(
        "Desea pausar los contratos Biotoken Factory y Orderbook?"
      );
      if (confirm) {
        notification("pauseContracts", "hint", "Pausando contratos...");
        const pauseBiotokenFactory = await nftRequestContract.setPaused();
        await pauseBiotokenFactory.wait();

        const pauseOrderbook = await orderBookContract.setPaused();
        await pauseOrderbook.wait();

        notification(
          "pauseContracts",
          "success",
          "Contratos pausados correctamente."
        );
        await getContractsStatus();
      }
    } catch (error) {
      notification("updateOwnerAddress", "error", (error as Error).message);
    }
  };

  useEffect(() => {
    // getContractsStatus();
  }, []);

  return {
    orderBookContract,
    auditorsContract,
    tcoeContract,
    daiContract,
    nftRequestContract,
    getLoggedAddress,
    updateOwnerAddress,
    pauseContracts,
    contractsStatus,
  };
};

export default useWallet;
