import { Card, CardInfo } from "../EmailAndAddress/emailAndAddressStyles.js";
import {
    TokenPriceContainer,
    Price,
    PriceTitle,
    Button,
    PriceContainer,
} from "./tokenPriceStyles.js";
import useTokenPrice from "./useTokenPrice";
import useAuth from "../../Auth/useAuth";
import useMarketplaceCommision from "./useCommission";

const OrderBookCommission = () => {
    const { updateCommission, commission } = useMarketplaceCommision();
    const { user } = useAuth();

    return (
        <Card>
            <CardInfo>
                <TokenPriceContainer>
                    <PriceTitle>Comisión Marketplace</PriceTitle>
                    <PriceContainer>
                        <Price>
                            {commission ? `${commission} %` : "Cargando..."}
                        </Price>
                    </PriceContainer>
                    {user?.isOwner ? (
                        <Button onClick={updateCommission}>Modificar</Button>
                    ) : null}
                </TokenPriceContainer>
            </CardInfo>
        </Card>
    );
};

export default OrderBookCommission;
