import {Container, HomeCards, HomeContainer, SubTitle, Title} from "./homeStyles";
import EmailAndAddress from "./EmailAndAddress/EmailAndAddress";
import TokenPrice from "./TokenPrice/TokenPrice";
import BalanceControl from "./BalanceControl/BalanceControl";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import React, {useEffect, useState} from "react";
import useQuotes, {Quote} from "./useQuotes";
import {Avatar, Button, CardHeader, CircularProgress} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import AddEditQuote from "./AddEditQuote";
import useAuth from "../Auth/useAuth";
import OrderBookCommission from "./TokenPrice/OrderbookCommision";
import useAnalytics from "./useAnalytics";
import AnalyticsCard from "./Card";
import Loader from "../../components/Loader/Loader";
import Grid2 from "@mui/material/Unstable_Grid2";
import Grid from "@mui/material/Grid";

const Home = () => {
    const {quotes, updateQuotes} = useQuotes();
    const {analytics, updateAnalytics} = useAnalytics();
    const {user} = useAuth();
    const [dialogOpen, setDialogOpen] = useState<boolean>(false);
    const [selectedQuote, setSelectedQuote] = useState<Quote | undefined>();

    useEffect(() => {
        updateQuotes();
        updateAnalytics();
    }, []);

    return (
        <HomeContainer>
            <Title>Estado</Title>

            <HomeCards>
                <EmailAndAddress/>
                {user?.isOwner ? <TokenPrice/> : <></>}
                {user?.isOwner ? <OrderBookCommission/> : <></>}
                <BalanceControl/>
                {/* <Chart /> */}
            </HomeCards>

            {user?.isOwner ? <>
                <Title>Analiticas</Title>
                {analytics ? <>

                    <SubTitle>Tokens</SubTitle>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={4}>
                            <AnalyticsCard title={"Tokens Emitidos"}
                                           content={analytics.tokens_emited.toLocaleString("es") + " TCOE"}/>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <AnalyticsCard title={"Tokens Disponibles"}
                                           content={analytics.tokens_available.toLocaleString("es") + " TCOE"}/>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <AnalyticsCard title={"Tokens Totales"}
                                           content={analytics.tokens_total.toLocaleString("es") + " TCOE"}/>
                        </Grid>
                    </Grid>

                    <SubTitle>Usuarios</SubTitle>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={4}>
                            <AnalyticsCard title={"Total"} content={analytics.user_count.toLocaleString("es")}/>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <AnalyticsCard title={"Activos"} content={analytics.validated_users.toLocaleString("es")}/>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <AnalyticsCard title={"Sin Validar"}
                                           content={analytics.pending_users.toLocaleString("es")}/>
                        </Grid>
                    </Grid>

                    <SubTitle>Solicitudes</SubTitle>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6} md={4} lg={2.4}>
                            <AnalyticsCard title={"Aprobadas"}
                                           content={analytics.approved_requests.toLocaleString("es")}/>
                        </Grid>

                        <Grid item xs={12} sm={6} md={4} lg={2.4}>
                            <AnalyticsCard title={"Aprobadas por oráculo"}
                                           content={analytics.approved_by_auditor_requests.toLocaleString("es")}/>
                        </Grid>

                        <Grid item xs={12} sm={6} md={4} lg={2.4}>
                            <AnalyticsCard title={"Rechazadas"}
                                           content={analytics.rejected_requests.toLocaleString("es")}/>
                        </Grid>

                        <Grid item xs={12} sm={6} md={4} lg={2.4}>
                            <AnalyticsCard title={"En Revisión"}
                                           content={analytics.reviewing_requests.toLocaleString("es")}/>
                        </Grid>

                        <Grid item xs={12} sm={6} md={4} lg={2.4}>
                            <AnalyticsCard title={"Pendientes"}
                                           content={analytics.pending_requests.toLocaleString("es")}/>
                        </Grid>

                    </Grid>

                </> : <div style={{
                    display: "flex",
                    alignContent: 'center',
                    alignItems: "center"
                }}><CircularProgress/></div>}

            </> : <></>}

            {user?.isOwner ? (
                <>
                    <div style={{display: "grid", gridTemplateColumns: "auto auto"}}>
                        <Title>Cotizaciones Sugeridas</Title>
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "row-reverse",
                                alignItems: "center",
                            }}
                        >
                            <Button
                                variant="outlined"
                                onClick={() => {
                                    setSelectedQuote(undefined);
                                    setDialogOpen(true);
                                }}
                            >
                                Agregar Nueva Cotizacion
                            </Button>
                        </div>
                    </div>

                    <Container>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell sx={{color: "white"}}>Nombre</TableCell>
                                    <TableCell sx={{color: "white"}}>Cotizacion</TableCell>
                                    <TableCell sx={{color: "white"}}>Acciones</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {quotes?.map((q) => {
                                    return (
                                        <TableRow key={"quote_" + q._id}>
                                            <TableCell>
                                                <CardHeader
                                                    avatar={<Avatar alt={q.name} src={q.icon}/>}
                                                    title={q.name}
                                                />
                                            </TableCell>
                                            <TableCell>{q.quote} USD</TableCell>
                                            <TableCell>
                                                <IconButton
                                                    aria-label="delete"
                                                    onClick={() => {
                                                        setSelectedQuote(q);
                                                        setDialogOpen(true);
                                                    }}
                                                >
                                                    <ModeEditIcon/>
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </Container>
                    <AddEditQuote
                        open={dialogOpen}
                        quote={selectedQuote}
                        onSave={async () => {
                            await updateQuotes();
                        }}
                        handleClose={() => {
                            setDialogOpen(false);
                        }}
                    />
                </>
            ) : (
                <></>
            )}
        </HomeContainer>
    );
};

export default Home;
