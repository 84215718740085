import styled from "styled-components";
import TableCell from "@mui/material/TableCell";
import {tableCellClasses} from "@mui/material";
import TableRow from "@mui/material/TableRow";

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: "black",
        color: "white",
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

export const StyledTableRow = styled(TableRow)(({ theme }) => ({
    // "&:nth-of-type(odd)": {
    //     backgroundColor: "#eeeeee",
    // },
    // // hide last border
    // "&:last-child td, &:last-child th": {
    //     border: 0,
    // },
}));
