import {useCallback, useState, useEffect} from "react";
import {useWallet} from "../../Wallet";
import useNotify from "../../Notify/useNotify";
import {ethers} from "ethers";
import {parse} from "node:url";

const useMarketplaceCommision = () => {
    const {orderBookContract} = useWallet();
    const {notification} = useNotify();

    const [commission, setCommission] = useState<number | null>(null);

    const getCommission = useCallback(async () => {
        const res = await orderBookContract.commission();
        setCommission(+res);
    }, [orderBookContract]);

    const updateCommission = async () => {
        try {
            const newCommissionStr = prompt("Ingrese nuevo valor de comisión");
            if (newCommissionStr) {
                const newCommission = parseInt(newCommissionStr);
                notification("updateCommission", "hint", "Actualizando comisión...");

                if(!isNaN(newCommission) && newCommission > 0 && newCommission < 100 ) {
                    const tx = await orderBookContract.updateCommission(newCommission);
                    await tx.wait();

                    setCommission(+newCommission!);

                    notification(
                        "approveRequest",
                        "success",
                        "Comisión actualizada correctamente."
                    );
                } else {
                    notification(
                        "approveRequest",
                        "success",
                        "Comisión inválida, debe estar entre 0 y 100"
                    );
                }
            }

        } catch (error) {
            console.log(error);
            notification("approveRequest", "error", (error as Error).message);
        }
    };

    useEffect(() => {
        getCommission();
    }, [commission]);

    return {updateCommission, commission};
};

export default useMarketplaceCommision;
