import styled from "styled-components";
import Button from "@mui/material/Button";
import theme from "../../theme";

export const Container = styled.div`
  background-color: ${theme.palette.secondary.card};
  padding: 30px;
  border-radius: 10px;
  box-shadow: -3px 9px 5px -6px rgba(0, 0, 0, 0.39);
  -webkit-box-shadow: -3px 9px 5px -6px rgba(0, 0, 0, 0.39);
  -moz-box-shadow: -3px 9px 5px -6px rgba(0, 0, 0, 0.39);
`;

export const Title = styled.h1`
  font-size: 30px;
  color: white;
  flex: 1;
`;

export const SubTitle = styled.h1`
  font-size: 20px;
  color: white;
  text-align: center;
`;

export const RequestsContainer = styled.div`
  border: 1px solid ${theme.palette.secondary.tabs};
  padding: 15px;
  margin-bottom: 15px;
  box-shadow: -3px 9px 5px -6px rgba(0, 0, 0, 0.24);
  -webkit-box-shadow: -3px 9px 5px -6px rgba(0, 0, 0, 0.24);
  -moz-box-shadow: -3px 9px 5px -6px rgba(0, 0, 0, 0.24);
`;

export const RequestTitle = styled.h6`
  color: ${theme.palette.primary.main};
  font-weight: bold;
  border-bottom: 0.5px solid ${theme.palette.secondary.tabs};
`;

export const ContainerBtns = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const BtnAccept = styled.button`
  background: none;
  color: ${theme.palette.secondary.green};
  font-weight: bold;
  border: 1px solid ${theme.palette.secondary.green};
  border-radius: 5px;
  padding: 5px;
  cursor: pointer;
`;

export const BtnDisabled = styled.button`
  background: none;
  color: gray;
  font-weight: bold;
  border: 1px solid gray;
  border-radius: 5px;
  padding: 5px;
  cursor: pointer;
`;

export const BtnDeny = styled.button`
  background: none;
  color: ${theme.palette.secondary.wrong};
  font-weight: bold;
  border: 1px solid ${theme.palette.secondary.wrong};
  border-radius: 5px;
  padding: 5px;
  cursor: pointer;
`;

export const BtnRevision = styled.button`
  color: #f4a551;
  font-weight: bold;
  border: 1px solid #f4a551;
  border-radius: 5px;
  padding: 5px;
  cursor: pointer;
  background: none;
`;

export const Loading = styled.h1`
  color: ${theme.palette.primary.main};
`;

export const FiltrarBy = styled.select`
  width: 90%;
  height: 50px;
  border: 1px solid ${theme.palette.secondary.tabs};
  background: none;
  margin-bottom: 15px;
  color: ${theme.palette.primary.main};
  padding: 5px;
  border-radius: 5px;
`;

export const Option = styled.option`
  color: ${theme.palette.primary.main};
  background-color: ${theme.palette.secondary.card};
`;

export const SearchButton = styled(Button)`
  margin-top: 10px;
  border-radius: 5px;
  padding: 10px;
  border: none;
  background: rgb(3, 192, 220);
  background: linear-gradient(
    107deg,
    rgba(3, 192, 220, 1) 0%,
    rgba(109, 231, 79, 1) 100%
  );
  cursor: pointer;
  font-weight: bold;
`;
