import styled from "styled-components";
import theme from "../../theme";

export const HomeContainer = styled.div`
    display: grid;
    gap: 20px;
    width: 100%;
`;

export const HomeCards = styled.div`
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 10px;
    width: 100%;
`;

export const Container = styled.div`
  background-color: ${theme.palette.secondary.card};
  border-radius: 10px;
  box-shadow: -3px 9px 5px -6px rgba(0, 0, 0, 0.39);
  -webkit-box-shadow: -3px 9px 5px -6px rgba(0, 0, 0, 0.39);
  -moz-box-shadow: -3px 9px 5px -6px rgba(0, 0, 0, 0.39);
`;

export const Title = styled.h1`
  font-size: 30px;
  color: white;
    margin: 10;
`;

export const SubTitle = styled.h1`
  font-size: 20px;
  color: white;
`;
