import jsDownload from "js-file-download";
import {NftRequestProps} from "../../../containers/NFTRequests/types";
import axiosInstance from "../../axios";

const getPdf = async (id: string, tokensGiven: string) => {
    const res = await axiosInstance.get<string>(`/request/${id}/pdf`, {
        params: {tokensGiven},
    });

    if (res.data) {
        return res.data;
    }

    return null;
};

const approve = async (id: string, tokensGiven: number) => {
    const res = await axiosInstance.post<NftRequestProps>(
        `/request/${id}/approve`,
    );

    if (res.data) {
        return res.data;
    }

    return null;
};

const process = async (id: string) => {
    const res = await axiosInstance.post<NftRequestProps>(
        `/request/${id}/process`
    );

    if (res.data) {
        return res.data;
    }

    return null;
};

const reject = async (id: string, reason: string) => {
    const res = await axiosInstance.post<NftRequestProps>(
        `/request/${id}/reject`
        , {
            reason
        });

    if (res.data) {
        return res.data;
    }

    return null;
};

const requestMoreInfo = async (
    id: string,
    reason: string,
    observations: string
) => {
    const res = await axiosInstance.post<NftRequestProps>(
        `/request/${id}/request_more_info`,
        {
            reason,
            observations,
        }
    );

    if (res.data) {
        return res.data;
    }

    return null;
};

const edit = async (request: NftRequestProps) => {
    const res = await axiosInstance.patch<NftRequestProps>(
        `/request/${request._id}`,
        {...request}
    );

    if (res.data) {
        return res.data;
    }

    return null;
};

const approveWithAuditor = async (id: string) => {
    const res = await axiosInstance.post<NftRequestProps>(
        `/request/${id}/approve`,
    );

    if (res.data) {
        return res.data;
    }

    return null;
};

const downloadCsv = async () => {
    const res = await axiosInstance.get<Blob>("/request/csv", {
        responseType: "blob",
    });

    if (res.data) {
        jsDownload(res.data, "solicitudes.csv");
    }
};

const methods = {
    approve,
    approveWithAuditor,
    getPdf,
    downloadCsv,
    edit,
    process,
    reject,
    requestMoreInfo
};
export default methods;
