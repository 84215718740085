import { useCallback, useState, useEffect } from "react";
import { useWallet } from "../../Wallet";
import useNotify from "../../Notify/useNotify";
import axios from "../../../services/axios";
import { ethers } from "ethers";

const useTokenPrice = () => {
  const { orderBookContract } = useWallet();
  const { notification } = useNotify();

  const [daiPerTCOE, setDaiPerTCOE] = useState<number | null>(null);

  const getDaiPerTCOE = useCallback(async () => {
    const res = ethers.utils.formatEther(await orderBookContract.daiPerTCOE());
    setDaiPerTCOE(+res);
  }, [orderBookContract]);

  const updateTCOEPrice = async () => {
    try {
      const newTCOEPrice = prompt("Ingrese nuevo precio de TCOE");

      if (isNaN(parseFloat(newTCOEPrice!))) {
        return notification(
          "approveRequest",
          "error",
          "Cantidad de TCOE no es un número"
        );
      }
      notification("updateTCOEPrice", "hint", "Actualizando precio...");

      const parsedPrice = ethers.utils.parseEther(newTCOEPrice?.toString()!);

      const tx = await orderBookContract.updateDaiPerTCOE(parsedPrice);
      notification("updateTCOEPrice", "hint", "Actualizando precio...");
      await tx.wait();

      setDaiPerTCOE(+newTCOEPrice!);
      await axios.post("/price", {
        price: newTCOEPrice,
      });

      notification(
        "approveRequest",
        "success",
        "Precio actualizado correctamente."
      );
    } catch (error) {
      console.log(error);
      notification("approveRequest", "error", (error as Error).message);
    }
  };

  useEffect(() => {
    getDaiPerTCOE();
  }, [daiPerTCOE]);

  return { updateTCOEPrice, daiPerTCOE };
};

export default useTokenPrice;
