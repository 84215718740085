import moment from "moment";
import { EventsContainer, EventsCard, Title, SpanCopy } from "./eventsStyles";
import useEventsWrapper from "./useEventsWrapper";
import { NotificationProps } from "./types";
import Loader from "../../components/Loader/Loader";
import { ContainerPagination } from "../../components/Users/userStyles";
import Pagination from "@mui/material/Pagination";
import { ChangeEvent } from "react";
import RefreshButton from "../../components/RefreshButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { CopyToClipboard } from "react-copy-to-clipboard";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

const Events = () => {
  const { allEvents, page, setPage, pageCount, loading, refresh } =
    useEventsWrapper();

  const handleChange = (event: ChangeEvent<unknown>, _page: number) => {
    setPage(_page);
  };

  const handleCopy = () => {
    alert("Copiado al portapapeles.");
  };

  return (
    <EventsContainer>
      <EventsCard>
        <div style={{ display: "flex" }}>
          <Title>Últimos movimientos</Title>
          <RefreshButton onClick={refresh} />
        </div>
        {loading ? (
          <Loader />
        ) : (
          <TableContainer
            sx={{
              backgroundColor: "transparent",
            }}
            component={Paper}
          >
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell sx={{ color: "white" }}>ID</TableCell>
                  <TableCell sx={{ color: "white" }}>Titulo</TableCell>
                  <TableCell sx={{ color: "white" }}>Email</TableCell>
                  <TableCell sx={{ color: "white" }}>Body</TableCell>
                  <TableCell sx={{ color: "white" }}>Mas info</TableCell>
                  <TableCell sx={{ color: "white" }}>Fecha alta</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {allEvents.length ? (
                  allEvents.map((movement: NotificationProps) => (
                    <TableRow
                      key={movement._id}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}
                    >
                      <TableCell sx={{ color: "white" }}>
                        {movement._id.slice(0, 5)}...{" "}
                        <CopyToClipboard
                          text={movement._id}
                          onCopy={() => handleCopy()}
                        >
                          <SpanCopy>
                            <ContentCopyIcon
                              style={{
                                width: "15px",
                              }}
                            />
                          </SpanCopy>
                        </CopyToClipboard>
                      </TableCell>
                      <TableCell sx={{ color: "white" }}>
                        {movement.title}
                      </TableCell>
                      <TableCell sx={{ color: "white" }}>
                        {movement.profile?.email}
                      </TableCell>
                      <TableCell sx={{ color: "white" }}>
                        {movement.body}
                      </TableCell>
                      <TableCell sx={{ color: "white" }}>
                        {movement.additionalInfo
                          ? movement.additionalInfo
                          : null}
                      </TableCell>
                      <TableCell sx={{ color: "white" }}>
                        {moment(movement.createdAt)
                          .locale("es")
                          .format("DD-MM-YYYY HH:mm")}
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <h1>No hay movimientos</h1>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        )}

        <ContainerPagination>
          <Pagination
            count={pageCount}
            page={page}
            onChange={handleChange}
            color="primary"
            variant="outlined"
            shape="rounded"
            sx={{
              button: {
                color: "white",
              },
            }}
          />
        </ContainerPagination>
      </EventsCard>
    </EventsContainer>
  );
};

export default Events;
