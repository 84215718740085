import { useCallback, useState, useEffect } from "react";
import { useWallet } from "../../Wallet";
import { ethers } from "ethers";

const useBalanceControl = () => {
  const { auditorsContract, orderBookContract, tcoeContract } = useWallet();
  const [factoryBalance, setFactoryBalance] = useState<number | null>(null);
  const [orderbookBalance, setOrderbookBalance] = useState<number | null>(null);

  const getTCOEBalances = useCallback(async () => {
    const factoryRes = await tcoeContract.balanceOf(auditorsContract.address);
    setFactoryBalance(+ethers.utils.formatEther(factoryRes));

    const orderbookRes = await tcoeContract.balanceOf(orderBookContract.address);
    setOrderbookBalance(+ethers.utils.formatEther(orderbookRes));
  }, []);

  useEffect(() => {
    getTCOEBalances();
  }, [])
  

  return { factoryBalance, orderbookBalance };
};

export default useBalanceControl;
