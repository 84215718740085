import styled from "styled-components";
import theme from "../../theme";

export const EventsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const EventsCard = styled.div`
  width: 85%;
  background-color: ${theme.palette.secondary.card};
  border-radius: 10px;
  padding: 20px;
  margin-top: 30px;
  box-shadow: 1px 5px 5px 0px rgba(0, 0, 0, 0.23);
  -webkit-box-shadow: 1px 5px 5px 0px rgba(0, 0, 0, 0.23);
  -moz-box-shadow: 1px 5px 5px 0px rgba(0, 0, 0, 0.23);
  transition: all 0.3s ease-in-out;
  font-size: 5px;
  font-weight: bold;
`;

export const Title = styled.h1`
  font-size: 20px;
  color: #ffffff;
  text-align: center;
  flex: 1;
`;

export const NoEvents = styled.h3`
  font-size: 15px;
  color: #ffffff;
  text-align: center;
`;

export const Event = styled.div`
  border: 1px solid ${theme.palette.secondary.tabs};
  padding: 15px;
  margin-bottom: 15px;
  box-shadow: -3px 9px 5px -6px rgba(0, 0, 0, 0.24);
  -webkit-box-shadow: -3px 9px 5px -6px rgba(0, 0, 0, 0.24);
  -moz-box-shadow: -3px 9px 5px -6px rgba(0, 0, 0, 0.24);
`;

export const EventTitle = styled.h6`
  margin: 0;
  color: ${theme.palette.primary.main};
  font-weight: bold;
  font-size: 15px;
`;

export const EventDetail = styled.h6`
  color: ${theme.palette.primary.main};
  font-weight: bold;
  border-bottom: 0.5px solid ${theme.palette.secondary.tabs};
  font-size: 10px;
  margin-top: 15px;
  margin-bottom: 15px;
`;

export const SpanCopy = styled.span`
  cursor: pointer;
`;
