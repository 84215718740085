import { Container } from "../NFTRequests/requestsStyles";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import useNftRequests from "../NFTRequests/useNftRequests";
import { useEffect, useState } from "react";
import Paper from "@mui/material/Paper";
import TableContainer from "@mui/material/TableContainer";
import { Button } from "@mui/material";
import { Title } from "../Auditors/auditorsStyle";
import { EditOutlined } from "@mui/icons-material";

export default function AbsorptionRates() {
  const { getCrop, crops, editCrop } = useNftRequests();

  useEffect(() => {
    getCrop().then();
  }, []);

  return (
    <Container>
      <Title>Tasas de absorción</Title>
      <TableContainer
        sx={{
          backgroundColor: "transparent",
        }}
        component={Paper}
      >
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sx={{ color: "white" }}>Nombre</TableCell>
              <TableCell sx={{ color: "white" }}>Tipo</TableCell>
              <TableCell sx={{ color: "white" }}>Tasa de absorción</TableCell>
              <TableCell sx={{ color: "white" }}>Indice de rastrojo</TableCell>
              <TableCell sx={{ color: "white" }}>Días de cultivo</TableCell>
              <TableCell sx={{ color: "white" }}>Densidad Máxima</TableCell>
              <TableCell sx={{ color: "white" }}>Activo</TableCell>
              <TableCell sx={{ color: "white" }}>Acciones</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {crops?.map((c: any) => {
              return (
                <TableRow>
                  <TableCell sx={{ color: "white" }}>{c.name}</TableCell>
                  <TableCell>{c.type}</TableCell>
                  <TableCell>
                    {c.rate}
                    <Button
                      onClick={async () => {
                        let newValue = prompt(
                          "Ingrese el nuevo valor de tasa de absorción",
                          c.rate,
                        );
                        if (Number(newValue)) {
                          await editCrop(c._id, {
                            rate: Number(newValue),
                          }).then();
                          await getCrop();
                        }
                      }}
                    >
                      <EditOutlined />
                    </Button>
                  </TableCell>
                  <TableCell>
                    {c.stubbleRate}{" "}
                    <Button
                      onClick={async () => {
                        let newValue = prompt(
                          "Ingrese el nuevo indice de rastrojo",
                        );
                        if (Number(newValue)) {
                          await editCrop(c._id, {
                            stubbleRate: Number(newValue),
                          }).then();

                          await getCrop();
                        }
                      }}
                    >
                      <EditOutlined />
                    </Button>
                  </TableCell>
                  <TableCell>{c.cropDates}</TableCell>

                  <TableCell>
                    {Number(c.maxDensity).toLocaleString("es-AR")} pl/Ha
                  </TableCell>
                  <TableCell sx={{ color: "white" }}>
                    {c.active ? (
                      <div
                        style={{
                          color: "greenyellow",
                          display: "flex",
                          alignItems: "center",
                          gap: 20,
                          flexDirection: "row",
                          fontWeight: 800,
                        }}
                      >
                        ACTIVO
                      </div>
                    ) : (
                      <div
                        style={{
                          color: "red",
                          display: "flex",
                          alignItems: "center",
                          gap: 20,
                          flexDirection: "row",
                          fontWeight: 800,
                        }}
                      >
                        INACTIVO
                      </div>
                    )}
                  </TableCell>
                  <TableCell>
                    {c.active ? (
                      <Button
                        variant="contained"
                        onClick={() => {
                          editCrop(c._id, { active: false }).then();
                        }}
                      >
                        Desactivar
                      </Button>
                    ) : (
                      <Button
                        variant="contained"
                        onClick={() => {
                          editCrop(c._id, { active: true }).then();
                        }}
                      >
                        Activar
                      </Button>
                    )}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Container>
  );
}
