import styled from "styled-components";
import theme from "../../theme";

export const Container = styled.div`
  background-color: ${theme.palette.secondary.card};
  padding: 30px;
  border-radius: 10px;
  box-shadow: -3px 9px 5px -6px rgba(0, 0, 0, 0.39);
  -webkit-box-shadow: -3px 9px 5px -6px rgba(0, 0, 0, 0.39);
  -moz-box-shadow: -3px 9px 5px -6px rgba(0, 0, 0, 0.39);
`;

export const Title = styled.h1`
  font-size: 30px;
  color: white;
  flex: 1;
`;

export const RequestsContainer = styled.div`
  border: 1px solid ${theme.palette.secondary.tabs};
  padding: 15px;
  margin-bottom: 15px;
  box-shadow: -3px 9px 5px -6px rgba(0, 0, 0, 0.24);
  -webkit-box-shadow: -3px 9px 5px -6px rgba(0, 0, 0, 0.24);
  -moz-box-shadow: -3px 9px 5px -6px rgba(0, 0, 0, 0.24);
`;

export const RequestTitle = styled.h5`
  color: ${theme.palette.primary.cyan};
  font-weight: bold;
`;

export const RequestText = styled.h6`
  display: auto;
  color: ${theme.palette.primary.main};
`;

export const ContainerBtns = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const BtnAccept = styled.button`
  background: none;
  color: ${theme.palette.secondary.green};
  font-weight: bold;
  border: 1px solid ${theme.palette.secondary.green};
  border-radius: 5px;
  padding: 5px;
  cursor: pointer;
  :disabled {
    cursor: default;
    background: grey;
    color: black;
    border: 1px solid black;
  }
`;

export const BtnDeny = styled.button`
  background: none;
  color: ${theme.palette.secondary.wrong};
  font-weight: bold;
  border: 1px solid ${theme.palette.secondary.wrong};
  border-radius: 5px;
  padding: 5px;
  cursor: pointer;
  :disabled {
    cursor: default;
    background: grey;
    color: black;
    border: 1px solid black;
  }
`;

export const BtnRevision = styled.button`
  color: #f4a551;
  font-weight: bold;
  border: 1px solid #f4a551;
  border-radius: 5px;
  padding: 5px;
  cursor: pointer;
  background: none;
  :disabled {
    cursor: default;
    background: grey;
    color: black;
    border: 1px solid black;
  }
`;

export const BtnProcessing = styled.button`
  color: lightblue;
  font-weight: bold;
  border: 1px solid lightblue;
  border-radius: 5px;
  padding: 5px;
  cursor: pointer;
  background: none;
  :disabled {
    cursor: default;
    background: grey;
    color: black;
    border: 1px solid black;
  }
`;

export const Loading = styled.h1`
  color: ${theme.palette.primary.main};
`;

export const FiltrarBy = styled.select`
  width: 40%;
  height: 30px;
  border: 1px solid ${theme.palette.secondary.tabs};
  background: none;
  margin-bottom: 15px;
  color: ${theme.palette.primary.main};
  padding: 5px;
  border-radius: 5px;
`;

export const Option = styled.option`
  color: ${theme.palette.primary.main};
  background-color: ${theme.palette.secondary.card};
`;

export const ContainerDownload = styled.div`
  display: block;
`;

export const ImageRequest = styled.img`
  width: 200px;
  height: 200px;
  border-radius: 5px;
  border: 1px solid ${theme.palette.secondary.tabs};
  padding: 5px;
`;

export const SpanCopy = styled.span`
  cursor: pointer;
`;
