import { useState } from "react";
import {
  AppBar,
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
} from "@mui/material";
import AccountCircle from "@mui/icons-material/AccountCircle";
import useAuth from "../../containers/Auth/useAuth";
import Logo from "../../assets/imgs/LogoHorizontal.png";
import { Link } from "react-router-dom";

const Navbar = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { user, logout } = useAuth() || {};

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => setAnchorEl(null);

  const handleLogout = () => {
    handleClose();
    logout && logout();
  };

  return (
    <AppBar
      position="fixed"
      sx={{
        zIndex: (t) => t.zIndex.drawer + 1,
        backgroundColor: (t) => t.palette.secondary.main,
      }}
    >
      <Toolbar>
        <Typography variant="h6" component="div" flexGrow={1}>
          <Link to="/">
            <img
              src={Logo}
              style={{
                width: "100px",
              }}
            />
          </Link>
        </Typography>
        {user && (
          <>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleMenu}
              color="inherit"
            >
              <AccountCircle
                style={{
                  color: "white",
                  width: "20px",
                  height: "20px",
                }}
              />
            </IconButton>
            <Menu
              id="menu-appbar"
              keepMounted
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              anchorOrigin={{ vertical: "top", horizontal: "right" }}
              transformOrigin={{ vertical: "top", horizontal: "right" }}
              onClose={handleClose}
            >
              <MenuItem onClick={handleLogout}>Cerrar sesión</MenuItem>
            </Menu>
          </>
        )}
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;
