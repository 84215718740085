import {Feature, Map} from "ol";
import TileLayer from "ol/layer/Tile";
import View from "ol/View";
import {defaults} from "ol/control/defaults";
import {defaults as interactionDefaults} from "ol/interaction/defaults";
import {Vector as VectorSrc, XYZ} from "ol/source";
import {Polygon} from "ol/geom";
import {Vector} from "ol/layer";
import {Fill, Stroke, Style} from "ol/style";
import {applyStyle} from "ol-mapbox-style";
import VectorTileLayer from "ol/layer/VectorTile";

// const osmBaseLayer = new TileLayer({
//     visible: true,
//     source: new OSM({
//         opaque: false
//     })
// });

export const worldImagery = new TileLayer({
  source: new XYZ({
    attributions: ['Powered by Esri',
      'Source: Esri, DigitalGlobe, GeoEye, Earthstar Geographics, CNES/Airbus DS, USDA, USGS, AeroGRID, IGN, and the GIS User Community'],
    attributionsCollapsible: false,
    url: 'https://services.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}',
    maxZoom: 23
  }),
});

export const map = new Map({
  target: "map",
  layers: [worldImagery],
  view: new View({
    center: [0,0],
    zoom: 10,
  }),
  controls: defaults(),
  interactions: interactionDefaults({}),
});

export const referenceLayer = new VectorTileLayer({ declutter: true });
applyStyle(
    referenceLayer,
    "https://www.arcgis.com/sharing/rest/content/items/2a2e806e6e654ea78ecb705149ceae9f/resources/styles/root.json?f=pjson",
    {
      transformRequest(url, type) {
        if (type === "Source") {
          return new Request(
              url.replace("/VectorTileServer", "/VectorTileServer/")
          );
        }
      },
    }
).then((_layer) => {
  map.addLayer(referenceLayer);
});
