import axiosInstance from "../../axios";

const login = async (address: string): Promise<string | null> => {
  const res = await axiosInstance.post(`/auth/login`, {
    address,
  });

  if(res.data) {
    return res.data['access_token']
  }
  return null
};

const methods = { login };
export default methods;
