import styled from "styled-components";
import theme from "../../theme";

export const UsersContainer = styled.div`
  border: 1px solid ${theme.palette.secondary.tabs};
  padding: 15px;
  margin-bottom: 15px;
  box-shadow: -3px 9px 5px -6px rgba(0, 0, 0, 0.24);
  -webkit-box-shadow: -3px 9px 5px -6px rgba(0, 0, 0, 0.24);
  -moz-box-shadow: -3px 9px 5px -6px rgba(0, 0, 0, 0.24);
`;

export const User = styled.div`
  color: ${theme.palette.primary.main};
`;

export const UserText = styled.h6`
  margin-bottom: 5px;
  border-bottom: 1px solid ${theme.palette.secondary.tabs};
`;

export const PreviousBtnPagination = styled.button`
  background: none;
  color: ${theme.palette.secondary.white};
  font-weight: bold;
  border: 1px solid ${theme.palette.secondary.white};
  border-radius: 5px;
  padding: 5px;
  cursor: pointer;
`;

export const NextBtnPagination = styled.button`
  background: none;
  color: ${theme.palette.secondary.white};
  font-weight: bold;
  border: 1px solid ${theme.palette.secondary.white};
  border-radius: 5px;
  padding: 5px;
  cursor: pointer;
  margin-right: 0;
  margin-left: auto;
`;

export const ContainerPagination = styled.div`
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Button = styled.button`
  margin-top: 10px;
  border-radius: 5px;
  padding: 10px;
  border: none;
  background: rgb(3, 192, 220);
  background: linear-gradient(
    107deg,
    rgba(3, 192, 220, 1) 0%,
    rgba(109, 231, 79, 1) 100%
  );
  cursor: pointer;
  font-weight: bold;
`;

export const SpanCopy = styled.span`
  cursor: pointer;
`;

export const SearchContainer = styled.div`
  display: flex;
`
