import { useEffect, useState } from "react";
import axios from "../../services/axios";

const useEventsWrapper = () => {
  const [allEvents, setAllEvents] = useState<[]>([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState<number>(1);
  const [pageCount, setPageCount] = useState<number>(0);

  const getAllEvents = async () => {
    try {
      setLoading(true);
      const res = await axios.get(`/notification?page=${page}`);
      if (res.data) {
        setAllEvents(res.data.items);
        setPageCount(res.data.pageCount);
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    getAllEvents();
  }, [page]);

  return {
    allEvents,
    page,
    setPage,
    pageCount,
    loading,
    refresh: getAllEvents,
  };
};

export default useEventsWrapper;
