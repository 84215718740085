import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  TextField,
  Button,
  DialogActions,
} from "@mui/material";
import useQuotes, { Quote } from "../useQuotes";
import { MuiFileInput } from "mui-file-input";
import { useEffect, useRef, useState } from "react";
import Loader from "../../../components/Loader/Loader";

interface Props {
  handleClose: () => void;
  onSave: () => void;
  open: boolean;
  quote?: Quote;
}

export interface FormData {
  _id?: string;
  name?: string;
  quote?: number;
  icon?: string;
  updatedIcon?: File;
}

const AddEditQuote = ({ open, quote, onSave, handleClose }: Props) => {
  const iconRef = useRef<HTMLInputElement>(null);
  const [formData, setFormData] = useState<FormData>({});
  const [loading, setLoading] = useState(false);

  const { createEditQuote } = useQuotes();

  useEffect(() => {
    let data = {};
    if (quote) {
      data = { ...quote };
    }
    if (iconRef.current) {
      iconRef.current.value = "";
    }
    setFormData(data);
  }, [quote]);

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>{quote ? "Editar" : "Nueva"} Cotización</DialogTitle>
      <DialogContent>
        {/*<DialogContentText>*/}
        {/*  To subscribe to this website, please enter your email address here. We*/}
        {/*  will send updates occasionally.*/}
        {/*</DialogContentText>*/}
        <TextField
          margin="dense"
          id="name"
          label="Nombre"
          type="text"
          value={formData?.name ?? ""}
          fullWidth
          variant="standard"
          onChange={(e) => {
            let data = { ...formData };
            data.name = e.target.value;
            setFormData(data);
          }}
        />
        <TextField
          margin="dense"
          id="name"
          label="Precio"
          type="number"
          value={formData?.quote ?? ""}
          fullWidth
          variant="standard"
          onChange={(e) => {
            let data = { ...formData };
            let val = Number(e.target.value);
            if (val) {
              data.quote = val;
              setFormData(data);
            }
          }}
        />
        <Button variant="contained" component="label" sx={{ mt: "10px" }}>
          {formData.updatedIcon
            ? "Icono Seleccionado"
            : quote
            ? "Actualizar Icono"
            : "Subir Icono"}
          <input
            type="file"
            ref={iconRef}
            accept={"image/jpeg,image/png"}
            onChange={(e) => {
              if (e.target.files && e.target.files.length > 0) {
                const f = e.target.files[0];
                const data = { ...formData };
                data.updatedIcon = f;
                setFormData(data);
              }
            }}
            hidden
          />
        </Button>
      </DialogContent>

      <DialogActions>
        {loading ? (
          <Loader />
        ) : (
          <>
            <Button onClick={handleClose}>Cancelar</Button>
            <Button
              onClick={() => {
                setLoading(true);
                createEditQuote(formData)
                  .then(() => {
                    onSave();
                    handleClose();
                  })
                  .finally(() => setLoading(false));
              }}
            >
              {quote ? "Modificar" : "Crear"}
            </Button>
          </>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default AddEditQuote;
