import { useCallback, useEffect, useState } from "react";
import { useWallet } from "../Wallet";
import { useNotify } from "../Notify";
import { Auditors as AuditorsProps } from "../../contracts/typechain";
import { BigNumber } from "ethers";
import { auditor as auditorApi } from "../../services/api";
import useNotifications from "../../services/notifications/useNotifications";

const useAuditors = () => {
  const { auditorsContract } = useWallet();
  const { trackTx, notification } = useNotify();
  const { createNotification } = useNotifications();
  const [auditors, setAuditors] = useState<AuditorsProps.AuditorStructOutput[]>(
    []
  );

  const createAuditor = useCallback(
    async (address: string, email: string) => {
      const isEdit = auditors.some(
        (auditor) => auditor.auditorAddress === address
      );
      try {
        const tx = await auditorsContract.registerAuditor(address, email);
        notification(
          "createAuditor",
          "hint",
          `${isEdit ? "Editando" : "Agregando"} auditor...`
        );
        trackTx(tx.hash);
        const res = await tx.wait();
        const newAuditorEvent = res.events?.find(
          (x: any) => x.event === "AuditorCreated"
        );
        if (newAuditorEvent?.args) {
          const { args } = newAuditorEvent;
          const newAuditor = args as AuditorsProps.AuditorStructOutput;
          const baseAuditor = {
            idContract: Number(newAuditor.id),
            address: newAuditor.auditorAddress,
            email: newAuditor.email,
          };
          if (isEdit) {
            const editedAuditor = await auditorApi.edit(baseAuditor);
            setAuditors((acc) =>
              acc.map((x) => {
                if (x.auditorAddress === editedAuditor?.address) {
                  const xCopy = { ...x };
                  xCopy.email = baseAuditor.email;
                  xCopy.deleted = false;
                  return xCopy;
                }
                return x;
              })
            );
            await createNotification({
              title: `Auditor creado`,
              body: `Address: ${newAuditor.auditorAddress} | Email: ${newAuditor.email}`,
              profile: null,
            });
          } else {
            await auditorApi.create(baseAuditor);
            setAuditors((acc) => [...acc, newAuditor]);
          }
          notification(
            "createAuditor",
            "success",
            `Auditor ${isEdit ? "editado" : "agregado"} correctamente.`
          );
        }
      } catch (error) {
        notification("createAuditor", "error", (error as Error).message);
      }
    },
    [auditorsContract, auditors, trackTx, notification]
  );

  const getAuditors = useCallback(async () => {
    try {
      const result = await auditorsContract.getAllAuditors();
      if (result.length) {
        setAuditors(result);
      }
    } catch (error) {
      console.log(error);
    }
  }, [auditorsContract]);

  const deleteAuditor = useCallback(
    async (auditorId: number) => {
      try {
        const result = window.confirm(`Desea eliminar al auditor ${auditorId}`);
        if (!result) {
          return;
        }
        const tx = await auditorsContract.deleteAuditor(auditorId);
        notification("deleteAuditor", "hint", "Eliminando auditor...");
        trackTx(tx.hash);
        const res = await tx.wait();
        const deleteAuditorEvent = res.events?.find(
          (x) => x.event === "AuditorDeleted"
        );
        if (deleteAuditorEvent?.args) {
          const { args } = deleteAuditorEvent;
          auditorApi.activateDesactivate(Number(args[1]));
          setAuditors((acc) =>
            acc.map((x) => {
              if (x.id.toNumber() === args[1].toNumber()) {
                const xCopy = { ...x };
                xCopy.deleted = true;
                return xCopy;
              }
              return x;
            })
          );
          await createNotification({
            title: `Auditor eliminado`,
            body: `Address: ${args.auditorAddress} | Email: ${args.email}`,
            profile: null,
          });
          notification(
            "deleteAuditor",
            "success",
            "Auditor eliminado correctamente."
          );
        }
      } catch (error) {
        notification("deleteAuditor", "error", (error as Error).message);
      }
    },
    [auditorsContract, trackTx, notification]
  );

  useEffect(() => {
    if (!auditors?.length) {
      getAuditors();
    }
  }, [getAuditors, auditors?.length]);

  return {
    createAuditor,
    getAuditors,
    deleteAuditor,

    auditors,
  };
};

export default useAuditors;
