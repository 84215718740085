import axios, { AxiosError, AxiosResponse } from "axios";
import env from "../../env";
import { Magic } from "magic-sdk";

const customNodeOptions = {
  rpcUrl: env.RPC_URL,
  chainId: env.CHAIN_ID,
};

const magic = new Magic(env.MAGIC_KEY as string, {
  network: customNodeOptions as any, // or "ropsten" or "kovan",
  locale: "es",
});

const axiosInstance = axios.create({ baseURL: env.BACKEND_URL });

async function getMagicToken() {
  const storedToken = localStorage.getItem("local_magic_token");
  const storedExpiration = Number(
    localStorage.getItem("local_magic_token_expiration")
  );

  //If we have a storedToken and it is not expired, then return it
  if (storedToken && storedExpiration) {
    if (Date.now() < storedExpiration) {
      return storedToken;
    }
  }

  // 15 - minutes
  const lifespan = 15 * 60;
  const didToken = await magic!.user.generateIdToken({
    lifespan: lifespan,
    attachment: env.MAGIC_SECRET,
  });

  //Discount 10 seconds to the lifespan
  const expiration = Date.now() + (lifespan - 10) * 1000;

  localStorage.setItem("local_magic_token", didToken);
  localStorage.setItem("local_magic_token_expiration", String(expiration));

  return didToken;
}

axiosInstance.interceptors.request.use(async (axiosConfig) => {
  const magicToken = await getMagicToken();
  const headers: any = { authorization: `Bearer ${magicToken}` };

  const jwtToken = localStorage.getItem("jwt");

  if (jwtToken) {
    headers["x-jwt"] = `Bearer ${jwtToken}`;
  }

  return { ...axiosConfig, headers };
});

axiosInstance.interceptors.response.use(
  (res) => res,
  (err) => {
    const { isAxiosError, response, message } = err as AxiosError;
    if (isAxiosError) {
      console.error("AXIOS ERROR", message);
      const nullResponse: AxiosResponse = { ...response!!, data: null };
      return nullResponse;
    } else {
      console.error("STOCK ERROR", err);
      return null;
    }
  }
);

export default axiosInstance;
