import axiosInstance from "../../axios";
import { Auditors as AuditorsProps } from "../../../contracts/typechain";

interface Auditor {
  idContract: number;
  email: string;
  address: string;
}

const create = async (auditorStruct: Auditor): Promise<null> => {
  await axiosInstance.post(`/auditor/`, auditorStruct);
  return null;
};

const edit = async (auditorStruct: Auditor): Promise<AuditorsProps | null> => {
  const res = await axiosInstance.put<AuditorsProps>(
    `/auditor/`,
    auditorStruct
  );
  return res.data;
};

const activateDesactivate = async (idContract: number): Promise<null> => {
  await axiosInstance.delete(`/auditor/${idContract}`);
  return null;
};

const methods = { create, edit, activateDesactivate };
export default methods;
