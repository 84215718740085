import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from "@mui/material";
import useSustainableActivities from "./sustainableActivitiesWrapper";
import Loader from "../../components/Loader/Loader";

interface Props {
  open: boolean;
  onClose: () => void;
  onCreated: () => void;
}

export default function AddNewActivityForm(props: Props) {
  const handleClose = () => {
    props.onClose();
  };

  const { createActivity } = useSustainableActivities();
  const [loading, setIsLoading] = useState<boolean>(false);
  const [identifier, setIdentifier] = useState<string>("");
  const [title, setTitle] = useState<string>("");
  const [duration, setDuration] = useState<string>("");
  const [commission, setCommission] = useState<string>("");

  const handleAdd = async () => {
    setIsLoading(true);

    await createActivity(
      identifier,
      title,
      Number(duration) ?? 0,
      Number(commission) ?? 0
    );

    props.onCreated();
  };

  return (
    <Dialog open={props.open} onClose={handleClose}>
      <DialogTitle>Agregar Actividad</DialogTitle>
      <DialogContent>
        <DialogContentText></DialogContentText>
        <TextField
          autoFocus
          margin="dense"
          id="identifier"
          label="Identificador"
          value={identifier}
          onChange={(e) => setIdentifier(e.target.value)}
          fullWidth
          variant="standard"
        />
        <TextField
          autoFocus
          margin="dense"
          id="name"
          label="Nombre"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          fullWidth
          variant="standard"
        />
        <TextField
          autoFocus
          margin="dense"
          id="duration"
          label="Duración (años)"
          value={duration}
          onChange={(e) => setDuration(e.target.value)}
          fullWidth
          variant="standard"
        />
        <TextField
          autoFocus
          margin="dense"
          id="commision"
          label="Comision (%)"
          value={commission}
          onChange={(e) => setCommission(e.target.value)}
          fullWidth
          variant="standard"
        />
      </DialogContent>
      <DialogActions>
        {loading ? (
          <Loader />
        ) : (
          <>
            <Button onClick={handleClose}>Cancelar</Button>
            <Button
              onClick={async () => {
                await handleAdd();
              }}
            >
              Agregar
            </Button>
          </>
        )}
      </DialogActions>
    </Dialog>
  );
}
