import {
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  tableCellClasses,
  Typography,
} from "@mui/material";
import Button from "@mui/material/Button";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import Paper from "@mui/material/Paper";
import styled from "styled-components";
import {CID, RequestFile} from "../types";
import { DownloadForOffline } from "@mui/icons-material";
import useNftRequests from "../useNftRequests";
import {StyledTableCell, StyledTableRow} from "./styles";
import {mapRole} from "../../../typings/user";
import moment from "moment";

export type FileListProps = {
  open: boolean;
  files: RequestFile[];
  onClose: () => void;
};

export const FileList = (props: FileListProps) => {
  const { getPdfFile } = useNftRequests();
  return (
    <Dialog open={props.open} fullWidth maxWidth="md">
      <DialogTitle>Lista de Archivos</DialogTitle>
      <DialogContent dividers>
        <TableContainer>
          <Table aria-label="customized table">
            <TableHead>
              <StyledTableRow>
                <StyledTableCell width="20%">Fecha</StyledTableCell>
                <StyledTableCell align='left'>Nombre</StyledTableCell>
                <StyledTableCell width="20%" align='left'>Subido por</StyledTableCell>
                <StyledTableCell width="5%" align="left">Descargar</StyledTableCell>
              </StyledTableRow>
            </TableHead>
            <TableBody>
              {props.files?.map((row) => (
                <StyledTableRow key={row.name}>
                  <StyledTableCell style={{ width: 100 }} align="left">
                    {moment(row.createdAt).format('DD/MM/YYYY hh:mm')}
                  </StyledTableCell>
                  <StyledTableCell component="th" scope="row">
                    {row.name}
                  </StyledTableCell>
                  <StyledTableCell style={{ width: 100 }} align="left">
                    {row.uploader?.slice(0, 4) + "..." + row.uploader?.slice(-4)}
                    <Chip
                        color="secondary"
                        label={mapRole(row.role ?? "")}
                    />
                    {/*{row.cid?.slice(0, 8) + "..." + row.cid?.slice(-8)}*/}
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    <Button
                      onClick={() => {
                        getPdfFile(row);
                      }}
                      variant="contained"
                      color="secondary"
                    >
                      <DownloadForOffline></DownloadForOffline>
                    </Button>
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>{" "}
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="secondary"
          autoFocus
          onClick={props.onClose}
        >
          Cerrar
        </Button>
      </DialogActions>
    </Dialog>
  );
};
