import React, { useEffect } from "react";
import useAuth from "../../Auth/useAuth";
import { useWallet } from "../../Wallet";
import { Button } from "../TokenPrice/tokenPriceStyles";
import {
  Card,
  CardInfo,
  TextInfo,
} from "./emailAndAddressStyles";

const EmailAndAddress = () => {
  const { user } = useAuth();
  const { updateOwnerAddress } = useWallet();

  useEffect(() => {}, [user]);

  return (
    <Card>
      <CardInfo>
        <TextInfo>Address: {user?.address}</TextInfo>
        <TextInfo>Email: {user?.email}</TextInfo>
        <TextInfo>{user?.isOwner ? 'Owner' : 'Auditor'}</TextInfo>
        {user?.isOwner ? (
            <Button onClick={updateOwnerAddress}>Modificar owner</Button>
        ) : null}
      </CardInfo>
    </Card>
  );
};

export default EmailAndAddress;
