import jsDownload from "js-file-download";
import { UserProps } from "../../../typings/user";
import axiosInstance from "../../axios";

const getProfileByAddress = async (
  address: string
): Promise<UserProps | null> => {
  const res = await axiosInstance.get<UserProps | null>(
    `/profile/address/${address}`
  );

  return res.data;
};

const downloadCsv = async () => {
  const res = await axiosInstance.get<Blob>("/profile/csv", {
    responseType: "blob",
  });

  if (res.data) {
    jsDownload(res.data, "usuarios.csv");
  }
};

const methods = { getProfileByAddress, downloadCsv };
export default methods;
