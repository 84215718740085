import contractAddress from "../contracts/address.json";

const {
  REACT_APP_PROVIDER,
  REACT_APP_MAGIC_API_KEY,
  REACT_APP_RPC_URL,
  REACT_APP_CHAIN_ID,
  REACT_APP_BACKEND_URL,
  REACT_APP_MAGIC_SECRET,
  REACT_APP_FACTORY_ADDRESS,
  REACT_APP_ORDERBOOK_ADDRESS,
  REACT_APP_TCOE_ADDRESS,
  REACT_APP_DAI_ADDRESS,
  REACT_APP_IS_DEV,
  REACT_APP_OWNER_EMAIL,
  REACT_APP_BACKEND_URL_IMAGES,
  REACT_APP_WEB3_STORAGE,
} = process.env;

const env = {
  NODE_ENV: process.env.NODE_ENV,
  WEB3_PROVIDER: REACT_APP_PROVIDER,
  MAGIC_KEY: REACT_APP_MAGIC_API_KEY,
  RPC_URL: REACT_APP_RPC_URL,
  CHAIN_ID: REACT_APP_CHAIN_ID,
  BACKEND_URL: REACT_APP_BACKEND_URL,
  MAGIC_SECRET: REACT_APP_MAGIC_SECRET,
  BIOTOKEN_FACTORY_ADDRESS: REACT_APP_FACTORY_ADDRESS!!,
  ORDERBOOK_ADDRESS: REACT_APP_ORDERBOOK_ADDRESS!!,
  TCOE_ADDRESS: REACT_APP_TCOE_ADDRESS!!,
  ERC20_ADDRESS: REACT_APP_DAI_ADDRESS!!,
  OWNER_EMAIL: REACT_APP_OWNER_EMAIL!!,
  BACKEND_URL_IMAGES: REACT_APP_BACKEND_URL_IMAGES,
  WEB3_STORAGE_API_KEY: REACT_APP_WEB3_STORAGE,
};

export default env;
