import React, { useState } from "react";
import { Navigate } from "react-router-dom";
import useAuth from "./useAuth";
import Loader from "../../components/Loader/Loader";

import Logo from "../../assets/imgs/logo.svg";

import {
  Button,
  Web3Container,
  LoginContainer,
  LoaderContainer,
  LogoContainer,
  ElementsContainer,
  LogoImg,
  TitleLogin,
  SubTitleLogin,
} from "./authStyle";

const Auth = () => {
  const { user, loginWithWeb3Modal, loading } = useAuth() || {};

  const handleLogin = async () => {
    loginWithWeb3Modal && (await loginWithWeb3Modal());
  };

  if (user) {
    return <Navigate replace to="/" />;
  }

  if (loading) {
    return (
      <LoaderContainer>
        <Loader />
      </LoaderContainer>
    );
  }

  return (
    <React.Fragment>
      <LoginContainer>
        <LogoContainer>
          <LogoImg src={Logo} alt="logo" />
        </LogoContainer>

        <ElementsContainer>
          <TitleLogin>¡Bienvenido!</TitleLogin>
          <SubTitleLogin>Inicia sesión.</SubTitleLogin>
          <Web3Container>
            <Button onClick={handleLogin}>Connect wallet</Button>
          </Web3Container>
        </ElementsContainer>
      </LoginContainer>
    </React.Fragment>
  );
};

export default Auth;
