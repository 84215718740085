import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import useNftRequests from "./useNftRequests";
import { Container, Title, SubTitle } from "./requestsStyles";
import Loader from "../../components/Loader/Loader";
import DownloadIcon from "@mui/icons-material/Download";

const NftRequestDetail = () => {
  const { id } = useParams();
  const { getFiles, files, getPdfFile, loading } = useNftRequests();

  useEffect(() => {
    if (id) {
      getFiles(id);
    }
  }, []);

  const formatCreatedAt = (createdAt: string) => {
    const date = new Date(createdAt);
    return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
  };

  const handleDownload = (file: any) => {
    if (file) {
      getPdfFile(file);
    }
  };

  return (
    <Container>
      <Title>Detalle</Title>
      {loading ? (
        <Loader />
      ) : !loading && !files.length ? (
        <SubTitle>No hay archivos para descargar.</SubTitle>
      ) : (
        files?.map((file) => {
          return (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div
                key={file?._id}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                  border: "1px solid white",
                  borderRadius: "10px",
                  padding: "10px",
                  marginBottom: "10px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <SubTitle>Nombre: {file?.name}</SubTitle>
                  <SubTitle>
                    Fecha de reporte: {formatCreatedAt(file?.createdAt)}
                  </SubTitle>

                  <div
                    onClick={() => handleDownload(file)}
                    style={{
                      cursor: "pointer",
                      color: "white",
                    }}
                  >
                    <DownloadIcon />
                  </div>
                </div>
              </div>
            </div>
          );
        })
      )}
    </Container>
  );
};

export default NftRequestDetail;
