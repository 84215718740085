import { Card, CardInfo } from "../EmailAndAddress/emailAndAddressStyles.js";
import {
  TokenPriceContainer,
  Price,
  PriceTitle,
  Button,
  PriceContainer,
} from "./tokenPriceStyles.js";
import useTokenPrice from "./useTokenPrice";
import useAuth from "../../Auth/useAuth";

const TokenPrice = () => {
  const { updateTCOEPrice, daiPerTCOE } = useTokenPrice();
  const { user } = useAuth();

  return (
    <Card>
      <CardInfo>
        <TokenPriceContainer>
          <PriceTitle>Precio Sugerido TCOE</PriceTitle>
          <PriceContainer>
            <Price>
              {daiPerTCOE ? `${daiPerTCOE?.toFixed(2)} DAI` : "Cargando..."}
            </Price>
          </PriceContainer>
          {user?.isOwner ? (
            <Button onClick={updateTCOEPrice}>Actualizar precio</Button>
          ) : null}
        </TokenPriceContainer>
      </CardInfo>
    </Card>
  );
};

export default TokenPrice;
