import { useCallback, useContext } from "react";
import { AuthContext } from "./AuthProvider";
import { AuthProps } from "./types";

const useAuth = () => {
  const authContext: AuthProps | null = useContext(AuthContext);

  const getSignerAddress = useCallback(() => {
    return authContext?.user?.address;
  }, [authContext?.user?.address]);

  return { ...authContext, getSignerAddress };
};

export default useAuth;
