import { Card, CardInfo } from "../EmailAndAddress/emailAndAddressStyles.js";
import { TokenPriceContainer, Price, PriceTitle } from "./balanceControlStyles";
import useBalanceControl from "./useBalanceControl";
import useWallet from "../../Wallet/useWallet";
import { Button } from "../TokenPrice/tokenPriceStyles.js";
import useAuth from "../../Auth/useAuth";

const BalanceControl = () => {
  const { factoryBalance, orderbookBalance } = useBalanceControl();
  const { pauseContracts, contractsStatus } = useWallet();
  const { user } = useAuth();

  return (
    <Card>
      <CardInfo>
        <TokenPriceContainer>
          <PriceTitle>Balances - Estado</PriceTitle>
          <Price>
            Biotoken Factory: {factoryBalance?.toLocaleString("es")} TCOE -{" "}
            {`${contractsStatus.nftRequestPaused ? " Pausado" : " Activo"}`}
          </Price>
          <Price>
            Orderbook: {orderbookBalance} TCOE -{" "}
            {`${contractsStatus.orderbookPaused ? " Pausado" : " Activo"}`}
          </Price>
          {user?.isOwner ? (
            <Button onClick={pauseContracts}>Pausar contratos</Button>
          ) : (
            <></>
          )}
        </TokenPriceContainer>
      </CardInfo>
    </Card>
  );
};

export default BalanceControl;
