import { Outlet } from "react-router-dom";
import { Box, CssBaseline, Toolbar } from "@mui/material";
import Navbar from "../../components/Navbar";
import Sidebar from "./Sidebar";
import theme from "../../theme";
import useAuth from "../Auth/useAuth";

const Layout = () => {
  const { user } = useAuth();
  return (
    <Box style={{ display: "flex" }}>
      <CssBaseline />
      {user ? <Navbar /> : null}
      {user ? <Sidebar /> : null}
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          bgcolor: theme.palette.secondary.main,
          p: 3,
          minHeight: "100vh",
        }}
      >
        <Toolbar />
        <Outlet />
      </Box>
    </Box>
  );
};

export default Layout;
