import { IconButton } from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh";
import React from "react";

type Props = { onClick: () => void };

const RefreshButton: React.FC<Props> = ({ onClick }) => {
  return (
    <IconButton size="large" onClick={onClick} color="inherit">
      <RefreshIcon style={{ color: "white" }} />
    </IconButton>
  );
};

export default RefreshButton;
