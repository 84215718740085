import {useCallback} from "react";
import {bonds as bondsApi} from "../../services/api";

export const useBonds = () => {
  const getAllBonds = useCallback(async () => {
    return bondsApi.getBonds();
  }, []);

  const updateBond = async function(id: string, info: any) {
    return bondsApi.updateBond(id, info);
  }
  const changeBondStatus = async function(id: string, info: any) {
    return bondsApi.changeStatus(id, info);
  }

  const requestMoreInfo = async function(id: string, info: any) {
    return bondsApi.requestMoreInfo(id, info);
  }


  return {
    getAllBonds,
    updateBond,
    changeBondStatus,
    requestMoreInfo,
  };
};
