import { useCallback } from "react";
import Notify, { CustomNotificationObject, NotificationType } from "bnc-notify";
import { BLOCKNATIVE_DAPPID } from "../../constants";
import env from "../../env";

var notify = Notify({
  dappId: BLOCKNATIVE_DAPPID, // [String] The API key created by step one above
  networkId: Number(env.CHAIN_ID), // [Integer] The Ethereum network ID your Dapp uses.
  darkMode: true, // (default: false)
});

// TODO: REVISAR TRACKING EN POLYGON

const useNotify = () => {
  const trackTx = useCallback(async (hash: string) => {
    const { emitter } = notify.hash(hash);

    emitter.on("txPool", (transaction: any) => {
      // console.log(transaction);
      // uncomment one of the return statements below to customize the notification for this event
      // return {
      //   // type: 'hint', // change the type(style) of the notification
      //   // autoDismiss: 2000, // make the notification autodismiss after 2 secs
      //   // onclick: () => console.log('the notification has been clicked!'), // add a click handler to the notification
      //   message: 'this is a custom message for a pending transaction'
      // }
      // return false if you don't want a notification to be shown for this event
      // return false
      // do nothing or return undefined for the default notification
      // return undefined
    });

    // emitter.on("txConfirmed", (transaction) => console.log(transaction));

    emitter.on("all", (transaction) => {
      // 'all' handler gets called for every transaction event that you haven't registered a handler for
      /* return {
        type: "hint", // change the type(style) of the notification
        autoDismiss: 2000, // make the notification autodismiss after 2 secs
        onclick: () => console.log("the notification has been clicked!"), // add a click handler to the notification
        message: "this is a custom message for a pending transaction",
      };*/
    });
  }, []);

  const notification = useCallback(
    (eventCode: string, type: NotificationType, message: string) => {
      const notificationObject: CustomNotificationObject = {
        eventCode,
        type,
        message,
      };

      notify.notification(notificationObject);
    },
    []
  );

  return {
    trackTx,
    notification,
  };
};

export default useNotify;
