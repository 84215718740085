import styled from "styled-components";
import theme from "../../../theme";

export const TokenPriceContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    padding: 10px;
`;

export const TokenTitle = styled.h1`
    font-size: 13px;
`;

export const Price = styled.h1`
    font-size: 20px;
    background: -webkit-linear-gradient(
            ${theme.palette.secondary.green},
            ${theme.palette.secondary.blue}
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin: 0;
`;

export const PriceContainer = styled.div`
    margin-bottom: 5px;
    word-wrap: break-word;
`

export const PriceTitle = styled.h1`
    font-size: 25px;
    margin: 0;
    background: -webkit-linear-gradient(
            ${theme.palette.secondary.green},
            ${theme.palette.secondary.blue}
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
`;

export const Button = styled.button`
    margin-top: 10px;
    border-radius: 5px;
    padding: 10px;
    border: none;
    background: rgb(3, 192, 220);
    background: linear-gradient(
            107deg,
            rgba(3, 192, 220, 1) 0%,
            rgba(109, 231, 79, 1) 100%
    );
    cursor: pointer;
    font-weight: bold;
`;
