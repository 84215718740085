import axiosInstance from "../../axios";

const getBonds = async (): Promise<any> => {
  const res = await axiosInstance.get(`/bonds`);
  return res.data;
};

const updateBond = async(id: string, data: any): Promise<any> => {
  const res = await axiosInstance.patch(`/bonds/${id}`, {
    _id: id,
    ...data
  });
  return res.data;
}

const changeStatus = async(id: string, data: any): Promise<any> => {
  const res = await axiosInstance.patch(`/bonds/${id}/change_status`, {
    _id: id,
    ...data
  });
  return res.data;
}

const requestMoreInfo = async(id: string, data: any): Promise<any> => {
  const res = await axiosInstance.post(`/bonds/${id}/request_more_info`, {
    _id: id,
    ...data
  });
  return res.data;
}

export default { getBonds, updateBond, changeStatus, requestMoreInfo }