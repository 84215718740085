import styled from "styled-components";
import theme from "../../theme";

export const ConfigurationContainer = styled.div`
  background-color: ${theme.palette.secondary.card};
  padding: 30px;
  border-radius: 10px;
  box-shadow: -3px 9px 5px -6px rgba(0, 0, 0, 0.39);
  -webkit-box-shadow: -3px 9px 5px -6px rgba(0, 0, 0, 0.39);
  -moz-box-shadow: -3px 9px 5px -6px rgba(0, 0, 0, 0.39);
`;

export const Title = styled.h1`
  font-size: 30px;
  color: white;
`;

