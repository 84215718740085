import { createTheme } from '@mui/material';

const theme = createTheme({
    palette: {
        mode: 'dark',
        primary: {
            main: '#F2F2F2',
            label: '#B0B0B1',
            cyan: '#1BAF92',
        },
        secondary: {
            main: '#1E1E21',
            blue: '#03C0DC',
            blue2: '#1ECBB8',
            green: '#6DE74F',
            darkGreen: '#56DF6C',
            card: '#2C2C30',
            textDisabled: '#3E3E43',
            searchBar: '#2C2C3080',
            dni: '#616164',
            tabs: '#3E3E43',
            sale: '#F45151',
            wrong: '#E54C4C',
            black: '#000000',
            white: '#FFFFFF',
            gray: '#9A9A9A',
            hover: '#f5f5f5',
        }
    }
}); 

export default theme;