import Web3Modal from "web3modal";
import WalletConnectProvider from "@walletconnect/web3-provider";
import { INFURA_ID } from "../constants";
import theme from '../theme';

/*
  Web3 modal helps us "connect" external wallets:
*/

const web3ModalSetup = () =>
  new Web3Modal({
    network: "mainnet", // Optional. If using WalletConnect on xDai, change network to "xdai" and add RPC info below for xDai chain.
    cacheProvider: true, // optional
    theme: {
      background: theme.palette.secondary.card,
      border: theme.palette.secondary.tabs,
      hover: theme.palette.secondary.tabs,
      main: theme.palette.primary.main,
      secondary: theme.palette.secondary.gray,
      
    }, // optional. Change to "dark" for a dark theme.
    providerOptions: {
      /* walletconnect: {
        package: WalletConnectProvider, // required
        options: {
          infuraId: INFURA_ID, // required
        },
      }, */
    },
  });

export default web3ModalSetup;
