import React, { useState, useEffect, useCallback } from "react";
import useAuditors from "./useAuditors";
import {
  AuditorsContainer,
  Title,
  RegisterContainer,
  Input,
  Button,
  Text,
  AuditorsList,
  AuditorsUl,
  IndexLi,
  AuditorsLi,
  NoAuditors,
  SpanCopy,
  ContainerDelete,
} from "./auditorsStyle";
import { BtnDeny } from "../NFTRequests/requestsStyles";
import { CopyToClipboard } from "react-copy-to-clipboard";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

const Auditors = () => {
  const [auditorInput, setAuditorInput] = useState<string>("");
  const [emailInput, setEmailInput] = useState<string>("");
  const [isDisabled, setIsDisabled] = useState<boolean>(true);

  const { createAuditor, deleteAuditor, auditors } = useAuditors();

  const handleAdd = () => {
    createAuditor(auditorInput, emailInput);
    setAuditorInput("");
    setEmailInput("");
  };

  const handleDisabled = useCallback(() => {
    if (auditorInput.length && emailInput.length) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  }, [auditorInput.length, emailInput.length]);

  const handleCopy = () => {
    alert("Copiado al portapapeles.");
  };

  useEffect(() => {
    handleDisabled();
  }, [auditorInput, handleDisabled]);

  return (
    <React.Fragment>
      <AuditorsContainer>
        <Title>Oráculos</Title>
        <RegisterContainer>
          <Input
            placeholder="Ingresar address"
            value={auditorInput}
            onChange={(e: any) => setAuditorInput(e.target.value)}
          />
          <Input
            placeholder="Ingresar email"
            value={emailInput}
            onChange={(e: any) => setEmailInput(e.target.value)}
          />
          <Button onClick={() => handleAdd()} disabled={isDisabled}>
            Registrar oráculo
          </Button>
        </RegisterContainer>

        <AuditorsUl>
          {!auditors.length ? (
            <NoAuditors>
              <Text>No hay oráculos registrados.</Text>
            </NoAuditors>
          ) : (
            <TableContainer
              sx={{
                backgroundColor: "transparent",
              }}
              component={Paper}
            >
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ color: "white" }}>Nro.</TableCell>
                    <TableCell sx={{ color: "white" }}>Address</TableCell>
                    <TableCell sx={{ color: "white" }}>Email</TableCell>
                    <TableCell sx={{ color: "white" }}></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {auditors
                    .filter((x) => !x.deleted)
                    .map((auditor, index) => (
                      <TableRow
                        key={auditor.id.toNumber()}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell sx={{ color: "white" }}>
                          {index + 1}
                        </TableCell>
                        <TableCell sx={{ color: "white" }}>
                          {auditor.auditorAddress}
                          <CopyToClipboard
                            text={auditor.auditorAddress}
                            onCopy={() => handleCopy()}
                          >
                            <SpanCopy>
                              <ContentCopyIcon
                                style={{
                                  width: "15px",
                                }}
                              />
                            </SpanCopy>
                          </CopyToClipboard>
                        </TableCell>
                        <TableCell sx={{ color: "white" }}>
                          {auditor.email}
                          <CopyToClipboard
                            text={auditor.email}
                            onCopy={() => handleCopy()}
                          >
                            <SpanCopy>
                              <ContentCopyIcon
                                style={{
                                  width: "15px",
                                }}
                              />
                            </SpanCopy>
                          </CopyToClipboard>
                        </TableCell>
                        <TableCell sx={{ color: "white" }}>
                          {auditors.length > 1 && (
                            <ContainerDelete>
                              <BtnDeny
                                onClick={() =>
                                  deleteAuditor(auditor.id.toNumber())
                                }
                              >
                                Eliminar
                              </BtnDeny>
                            </ContainerDelete>
                          )}
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </AuditorsUl>
      </AuditorsContainer>
    </React.Fragment>
  );
};

export default Auditors;
