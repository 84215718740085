import {useRef} from "react";
import {map, referenceLayer, worldImagery} from "./map";
import {Feature, Map} from "ol";
import {Polygon} from "ol/geom";
import {Vector} from "ol/layer";
import {Vector as VectorSrc} from "ol/source";
import * as olProj from 'ol/proj';

export function useMap() {
  const mapRef = useRef<Map>();
  if (!mapRef.current) {
    mapRef.current = map;
  }

  const setCenter = (coordinates: number[]) => {
    // if (mapRef.current) {
    //   mapRef.current.setView(
    //     new View({
    //       center: olProj.fromLonLat(coordinates),
    //       zoom: 10,
    //     })
    //   );
    // }
  };

  const drawRegion = (coordinates: number[][]) => {

    var layers = map.getLayers()
    layers.forEach((l) => {
      if (l !== worldImagery && l !== referenceLayer) {
        map.removeLayer(l);
      }
    })

    const polyCoords = coordinates.map((i) => olProj.fromLonLat(i));
    const polygon = new Polygon([polyCoords])
    const polygonFeatureT = new Feature(polygon);
    const vectorSource = new VectorSrc({
      features: [polygonFeatureT],
    })

    const polygonFeature = new Vector({
      source: vectorSource,
      // style: new Style({
      //   stroke: new Stroke({ color: [255, 0, 0, 1], width: 3 }),
      //   fill: new Fill({ color: [0, 255, 0, 0.1] }),
      // }),
    });

    map.addLayer(polygonFeature);
    map.getView().fit(polygon)
  };

  return { map: mapRef.current, setCenter, drawRegion };
}
