import React, { useCallback, useEffect, useState } from "react";
import { BondsContainer, Title } from "./styles";
import Table from "@mui/material/Table";
import { TableContainer } from "@mui/material";
import Paper from "@mui/material/Paper";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import RequestBondInfoDialog from "./Modal/RequestBondInfoDialog";
import { Bond } from "./types";

import moment from "moment";
import { useBonds } from "./useBonds";
import { BondRequestMoreInfoDialog } from "./Modal/BondRequestMoreInfoDialog";

const Bonds = () => {
  const { getAllBonds } = useBonds();
  const [bonds, setBonds] = useState<Bond[] | undefined>();
  const [selectedBond, setSelectedBond] = useState<any | null>(null);

  const loadBonds = () => {
    getAllBonds().then((r) => {
      setBonds(r);
    });
  };

  useEffect(() => {
    loadBonds();
  }, []);

  const getMappedStatus = (status: string) => {
    switch (status) {
      case "approved":
        return "Aprobado"
      case "approve_queued":
        return "Aprobado - En Proceso"
      case "pending":
        return "Pendiente"
      case "published":
        return "Publicado"
      case "review":
        return "En revisión"
      case "rejected":
        return "Rechazado"
      default:
        return ""
    }
  }

  return (
    <React.Fragment>
      <BondsContainer>
        <Title>Bonos y Créditos</Title>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell>Nombre</TableCell>
                <TableCell>Usuario</TableCell>
                <TableCell align="right">Fecha de solicitud</TableCell>
                <TableCell align="right">Estado</TableCell>
                {/*<TableCell align="right">Acciones</TableCell>*/}
              </TableRow>
            </TableHead>
            <TableBody>
              {bonds?.map((row) => (
                <TableRow
                  key={row._id}
                  onClick={() => {
                    setSelectedBond(row);
                  }}
                  sx={{
                    cursor: "pointer",
                    "&:last-child td, &:last-child th": { border: 0 },
                  }}
                >
                  <TableCell component="th" scope="row">
                    {row._id}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {row.name}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {row.user}
                  </TableCell>
                  <TableCell align="right">
                    {row.createdAt
                      ? moment(row.createdAt).format("DD-MM-YYYY")
                      : ""}
                  </TableCell>
                  <TableCell align="right">
                    {getMappedStatus(row.status)}
                  </TableCell>
                  {/*<TableCell align="right"></TableCell>*/}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </BondsContainer>
      <RequestBondInfoDialog
        request={selectedBond}
        open={selectedBond}
        onSave={() => loadBonds()}
        onClose={() => {
          setSelectedBond(null);
        }}
      />
    </React.Fragment>
  );
};

export default Bonds;
