import React from "react";
import {Card, CardInfo, TextAnalytics} from "./EmailAndAddress/emailAndAddressStyles";
import {PriceTitle} from "./TokenPrice/tokenPriceStyles";

const AnalyticsCard = ({title, content, children}: { title: string, content: string, children?: any }) => {
    return (
        <Card>
            <CardInfo>
                <TextAnalytics>{title}</TextAnalytics>
                <PriceTitle>{content}</PriceTitle>
                {children}
            </CardInfo>
        </Card>
    );
};

export default AnalyticsCard;