import { LoaderSpinner, LoaderContainer } from './loaderStyles';

const Loader = () => {
  return (
    <LoaderContainer>
        <LoaderSpinner />
    </LoaderContainer>
  )
}

export default Loader; 