import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { Stack } from "@mui/system";
import {Grid, InputAdornment, TextField} from "@mui/material";
import moment from "moment/moment";
import { Bond } from "../types";
import { useBonds } from "../useBonds";
import { useEffect, useState } from "react";
import Loader from "../../../components/Loader/Loader";
import { disable } from "ol/rotationconstraint";
import { BondRequestMoreInfoDialog } from "./BondRequestMoreInfoDialog";

interface Props {
  request: Bond;
  open: boolean;
  onClose: () => void;
  onSave: () => void;
}

interface FormData {
  name: string;
  compensation: number;
  commission: number;
  country: string;
  issuer: string;
  coordinates?: {
    latitude: string;
    longitude: string;
  };
}

interface NumericInputProps {
  name: string;
  onChange: (value: string) => void;
  initialValue: string;
  disabled: boolean;
}

const NumericInput = ({
  initialValue,
  onChange,
  name,
  disabled,
}: NumericInputProps) => {
  const [inputValue, setInputValue] = useState("  ");

  useEffect(() => {
    // Set the initial value when the component mounts
    setInputValue(initialValue || "");
  }, [initialValue]);

  const handleInputChange = (event: any) => {
    let value = event.target.value;

    // Remove non-numeric characters except '-' and '.'
    value = value.replace(/[^-.\d]/g, "");

    // Ensure that '-' appears only at the beginning
    const hasNegativeSign = value.includes("-");
    value = hasNegativeSign ? `-${value.replace(/-/g, "")}` : value;

    // Ensure that '.' appears only once
    const decimalCount = value.split(".").length - 1;
    value =
      decimalCount > 1
        ? value.slice(0, value.lastIndexOf(".")) +
          value.slice(value.lastIndexOf(".") + 1)
        : value;

    setInputValue(value);
    onChange(value);
  };

  return (
    <TextField
      type="text"
      sx={{ width: "33%" }}
      id="outlined-basic"
      color="secondary"
      label={name}
      variant="outlined"
      value={inputValue}
      disabled={disabled}
      onChange={handleInputChange}
      placeholder="Enter a number"
    />
  );
};

function initialFormData(): FormData {
  return {
    name: "",
    compensation: 0,
    commission: 0,
    country: "",
    issuer: "",
  };
}

export default function RequestBondInfoDialog(props: Props) {
  const handleClose = () => {
    setFormData(initialFormData());
    props.onClose();
  };

  const [bond, setBond] = useState<Bond | undefined>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { updateBond, changeBondStatus } = useBonds();
  const [formData, setFormData] = useState<FormData>(initialFormData());
  const [isEdited, setIsEdited] = useState<boolean>(false);
  const [moreInfoOpen, setMoreInfoOpen] = useState<boolean>(false);

  useEffect(() => {
    if (props.request) {
      setBond(props.request);
    }
  }, [props.request]);

  useEffect(() => {
    if (bond) {
      setFormData({
        name: bond.name,
        compensation: (bond.tokensCalculated ?? 0),
        commission: bond.commission ?? 30,
        country: bond.country,
        coordinates: {
          latitude: bond.coordinates?.latitude
            ? String(bond.coordinates.latitude)
            : "",
          longitude: bond.coordinates?.longitude
            ? String(bond.coordinates.longitude)
            : "",
        },
        issuer: bond.issuer,
      });
    }
  }, [bond]);

  useEffect(() => {
    if (bond) {
      const edited =
        formData.name != bond.name ||
        formData.compensation != (bond.tokensCalculated ?? 0) ||
        formData.commission != (bond.commission ?? 30) ||
        formData.coordinates?.latitude != bond.coordinates?.latitude ||
        formData.coordinates?.longitude != bond.coordinates?.longitude ||
        formData.country != bond.country;

      setIsEdited(edited);
    }
  }, [formData, bond]);

  const update = async () => {
    if (bond) {
      setIsLoading(true);

      const data: any = { ...formData };
      data.tokensCalculated = data.compensation;

      const response = await updateBond(bond._id, data).finally(() =>
        setIsLoading(false)
      );

      setBond(response);
      props.onSave();
    }
  };

  const changeStatus = async (status: string) => {
    if (bond) {
      setIsLoading(true);
      const response = await changeBondStatus(bond._id, {
        status: status,
      }).finally(() => setIsLoading(false));
      setBond(response);
      props.onSave();
    }
  };
  const publish = async () => {
    return await changeStatus("published");
  };

  const approve = async () => {
    if (props.request.tokensCalculated && props.request.tokensCalculated > 0) {
      return await changeStatus("approve_queued");
    } else {
      alert("Debe ingresar la cantidad de tokens a emitir");
    }
  };

  const reject = async () => {
    return await changeStatus("rejected");
  };

  const openFile = (file: string) => {
    window.open(file);
  };

  const hasCUIL = () => {
    if (bond) {
      if (bond.cuilCID) {
        return true;
      } else if (bond.profile && bond.profile.documents) {
        const cuit = bond.profile.documents.find((i) => {
          return i.doctype === "CUIT/CUIL";
        });
        if (cuit) {
          return true;
        }
      }
    }

    return false;
  };

  return (
    <Dialog
      onClose={handleClose}
      maxWidth="lg"
      fullWidth
      aria-labelledby="customized-dialog-title"
      open={props.open}
    >
      <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
        Detalle de solicitud
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent dividers>
        <Stack direction="column" gap={2}>
          <Stack direction="row" gap={2}>
            <TextField
              sx={{ width: "33%" }}
              id="outlined-basic"
              color="secondary"
              label="ID"
              variant="outlined"
              disabled
              value={bond ? bond._id : " "}
            />
            <TextField
              sx={{ width: "33%" }}
              id="outlined-basic"
              color="secondary"
              label="Usuario"
              variant="outlined"
              disabled
              value={bond ? bond.user : " "}
            />
            <TextField
              sx={{ width: "33%" }}
              id="outlined-basic"
              color="secondary"
              label="Fecha Alta"
              variant="outlined"
              disabled
              value={moment(bond?.createdAt).format("DD/MM/YYYY")}
            />
          </Stack>
          <Stack direction="row" gap={2}>
            <TextField
              sx={{ width: "33%" }}
              id="outlined-basic"
              color="secondary"
              label="Nombre / Zona"
              disabled={bond?.status == "approved"}
              variant="outlined"
              onChange={(e) => {
                const data = { ...formData };
                data.name = e.target.value;
                setFormData(data);
              }}
              value={formData.name}
            />
            <TextField
              sx={{ width: "33%" }}
              id="outlined-basic"
              color="secondary"
              label="Ton. CO2 Compensado"
              disabled={bond?.status == "approved"}
              variant="outlined"
              onChange={(e) => {
                const data = { ...formData };
                const val = Number(e.target.value);
                if (val) {
                  data.compensation = val;
                } else if (!e.target.value) {
                  data.compensation = 0;
                }
                setFormData(data);
              }}
              value={formData.compensation}
            />
            <TextField
              sx={{ width: "33%" }}
              id="outlined-basic"
              color="secondary"
              label="Cant. TCOE Calculada"
              variant="outlined"
              disabled
              value={formData.compensation}
            />
            <TextField
              sx={{ width: "33%" }}
              id="outlined-basic"
              color="secondary"
              label="Estado"
              variant="outlined"
              disabled
              value={bond ? bond.status : " "}
            />
          </Stack>

          <Stack direction="row" gap={2}>
            <NumericInput
              name="Latitud"
              disabled={bond?.status == "approved"}
              initialValue={formData.coordinates?.latitude ?? ""}
              onChange={(value) => {
                const data = { ...formData };
                const coordinates = {
                  ...(data.coordinates ?? {
                    latitude: "",
                    longitude: "",
                  }),
                };

                coordinates.latitude = value;
                data.coordinates = coordinates;
                setFormData(data);
              }}
            />
            <NumericInput
              name="Longitud"
              initialValue={formData.coordinates?.longitude ?? ""}
              disabled={bond?.status == "approved"}
              onChange={(value) => {
                const data = { ...formData };
                const coordinates = {
                  ...(data.coordinates ?? {
                    latitude: "",
                    longitude: "",
                  }),
                };

                coordinates.longitude = value;
                data.coordinates = coordinates;
                setFormData(data);
              }}
            />
            <TextField
              sx={{ width: "33%" }}
              id="outlined-basic"
              color="secondary"
              label="País"
              disabled={bond?.status == "approved"}
              variant="outlined"
              onChange={(e) => {
                const data = { ...formData };
                data.country = e.target.value;
                setFormData(data);
              }}
              value={formData.country}
            />
          </Stack>
          <Stack direction="row" gap={2}>
            <TextField
              sx={{ width: "33%" }}
              id="outlined-basic"
              color="secondary"
              label="Emisor"
              disabled={bond?.status == "approved"}
              variant="outlined"
              onChange={(e) => {
                const data = { ...formData };
                data.issuer = e.target.value;
                setFormData(data);
              }}
              value={formData.issuer}
            />
            <TextField
              sx={{ width: "33%" }}
              id="outlined-basic"
              color="secondary"
              label="Comisión"
              disabled={bond?.status == "approved"}
              variant="outlined"
              onChange={(e) => {
                const data = { ...formData };
                const val = Number(e.target.value);
                if (val) {
                  data.commission = val;
                } else if (!e.target.value) {
                  data.commission = 0;
                }
                setFormData(data);
              }}
              value={formData.commission}
              InputProps={{
                endAdornment: (
                    <InputAdornment position="start">%</InputAdornment>
                ),
              }}
            />
          </Stack>

          <Grid container spacing={2}>
            {hasCUIL() ? (
              <Grid item xs={6}>
                <Button
                  variant="contained"
                  sx={{ width: "100%", height: "100%" }}
                  onClick={() => {
                    if (bond) {
                      if (bond.cuilCID) {
                        openFile(bond.cuilCID);
                      } else if (bond.profile && bond.profile.documents) {
                        const cuit = bond.profile.documents.find((i) => {
                          return i.doctype === "CUIT/CUIL";
                        });
                        if (cuit) {
                          openFile(cuit.url);
                        }
                      }
                    }
                  }}
                >
                  Ver constancia de CUIL / CUIT
                </Button>
              </Grid>
            ) : (
              <></>
            )}
            <Grid item xs={6}>
              <Button
                variant="contained"
                sx={{ width: "100%", height: "100%" }}
                onClick={() => {
                  if (bond) openFile(bond.bondCID);
                }}
              >
                Ver Bono
              </Button>
            </Grid>
            {bond?.companyAuthoritiesCID ? (
              <Grid item xs={6}>
                <Button
                  variant="contained"
                  sx={{ width: "100%", height: "100%" }}
                  onClick={() => {
                    if (bond) openFile(bond.companyAuthoritiesCID);
                  }}
                >
                  Ver acta de designacion de autoridades
                </Button>
              </Grid>
            ) : (
              <></>
            )}
            {bond?.companyAuthorizedCID ? (
              <Grid item xs={6}>
                <Button
                  variant="contained"
                  sx={{ width: "100%", height: "100%" }}
                  onClick={() => {
                    if (bond) openFile(bond.companyAuthorizedCID);
                  }}
                >
                  Ver acta de apoderados
                </Button>
              </Grid>
            ) : (
              <></>
            )}
            {bond?.companyStatuteCID ? (
              <Grid item xs={6}>
                <Button
                  variant="contained"
                  sx={{ width: "100%", height: "100%" }}
                  onClick={() => {
                    if (bond) openFile(bond.companyStatuteCID);
                  }}
                >
                  Ver estatuto de la compania
                </Button>
              </Grid>
            ) : (
              <></>
            )}
            <Grid item xs={6}>
              <Button
                variant="contained"
                sx={{ width: "100%", height: "100%" }}
                onClick={() => {
                  if (bond) openFile(bond.responsibleDniCID);
                }}
              >
                Ver DNI del Solicitante / Gerente / Presidente / Apoderado
              </Button>
            </Grid>

            {bond?.status == "published" ||
            bond?.status == "pending" ||
            bond?.status == "review" ? (
              <Grid item xs={6}>
                <Button
                  color="warning"
                  variant="contained"
                  sx={{ width: "100%", height: "100%" }}
                  onClick={() => {
                    setMoreInfoOpen(true);
                  }}
                >
                  Solicitar mas información
                </Button>
              </Grid>
            ) : null}
          </Grid>

          {/*<Stack direction="row" gap={2}>*/}
          {/*</Stack>*/}
          {/*<Stack direction="row" gap={2}>*/}
          {/*</Stack>*/}
          {/*<Stack direction="row" gap={2}>*/}
          {/*</Stack>*/}
        </Stack>
      </DialogContent>
      {bond?.status != "approved" ? (
        <DialogActions>
          {isLoading ? (
            <Loader />
          ) : isEdited ? (
            <Button onClick={update}>Guardar</Button>
          ) : bond?.status == "pending" || bond?.status == "review" ? (
            <Button variant="contained" color="warning" onClick={publish}>
              Publicar
            </Button>
          ) : bond?.status == "published" ? (
            <>
              <Button variant="contained" color="success" onClick={approve}>
                Aprobar
              </Button>
              <Button variant="contained" color="error" onClick={reject}>
                Rechazar
              </Button>
            </>
          ) : (
            <Button onClick={handleClose}>Cerrar</Button>
          )}
        </DialogActions>
      ) : (
        <></>
      )}
      {bond ? (
        <BondRequestMoreInfoDialog
          request={bond}
          open={moreInfoOpen}
          onMoreInfoRequested={() => {
            const updatedBond = { ...bond };
            updatedBond.status = "review";
            setBond(updatedBond);
            props.onSave();
          }}
          onClose={() => {
            setMoreInfoOpen(false);
          }}
        />
      ) : null}
    </Dialog>
  );
}
