import React, { createContext, useCallback, useEffect, useState } from "react";
import { providers } from "ethers";
import { Web3ModalSetup } from "../../helpers";
import { AuthProps, UserProps } from "./types";
import { ethers } from "ethers";
import { Magic } from "magic-sdk";
import { Auditors__factory, Auditors } from "../../contracts/typechain";
import { useNotify } from "../Notify";
import { auth } from "../../services/api";
import env from "../../env";

const customNodeOptions = {
  rpcUrl: env.RPC_URL,
  chainId: env.CHAIN_ID,
};
const magic = new Magic(env.MAGIC_KEY as string, {
  network: customNodeOptions as any, // or "ropsten" or "kovan",
  locale: "es",
});
const web3Modal = Web3ModalSetup();

export const AuthContext = createContext<AuthProps | null>(null);

const AuthProvider = ({ children }: React.PropsWithChildren) => {
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState<UserProps | null>(null);
  const [provider, setProvider] = useState<providers.Web3Provider | null>(null);
  const [loginMethod, setLoginMethod] = useState<string>("");
  const [signer, setSigner] = useState<any>();
  const { notification } = useNotify();

  const getJwt = async (address: string) => {
    const token = await auth.login(address);
    if (token) {
      localStorage.setItem("jwt", token);
    }

    return token
  };

  const logout = useCallback(async () => {
    web3Modal.clearCachedProvider();
    localStorage.removeItem("jwt");
    setUser(null);
    notification("LoggedOut", "success", "Deslogueado correctamente.");
  }, [loginMethod, notification]);

  const validateAuditorLogin = useCallback(
    async (_provider: providers.Web3Provider) => {
      try {
        const address = await _provider.getSigner().getAddress();
        const _signer = _provider.getSigner(address);
        setSigner(_signer);

        console.log(env.OWNER_EMAIL)
        console.log(env.BIOTOKEN_FACTORY_ADDRESS)
        const auditorsContract = new ethers.Contract(
          env.BIOTOKEN_FACTORY_ADDRESS,
          Auditors__factory.abi,
          _signer
        ) as Auditors;

        const owner = await auditorsContract.owner();
        if (owner === address) {
          return { email: env.OWNER_EMAIL, isOwner: true };
        }

        let { email, deleted } = await auditorsContract.getAuditorByAddress(
          address
        );
        if (!email || deleted) {
          return null;
        }

        return { email, isOwner: false };
      } catch (error) {
        console.log(error);
        return null;
      }
    },
    []
  );

  const loginWithWeb3Modal = useCallback(async () => {
    setLoading(true);
    try {
      const web3Provider = await web3Modal.connect();
      const ethersProvider = new ethers.providers.Web3Provider(web3Provider);
      const address = await ethersProvider.getSigner().getAddress();

      if (address && ethersProvider) {
        const emailAndOwner = await validateAuditorLogin(ethersProvider);
        if (emailAndOwner) {
          notification("Login", "hint", "Logueando...");

          localStorage.removeItem('local_magic_token')
          localStorage.removeItem('jwt')
          const didToken = await magic.auth.loginWithMagicLink({
            email: emailAndOwner?.email,
          });

          if(didToken) {
            await getJwt(address);

            setProvider(ethersProvider);
            setUser({
              address,
              isOwner: emailAndOwner.isOwner,
              email: emailAndOwner.email,
            });
            setLoginMethod("web3modal");
            notification(
                "Login",
                "success",
                "Bienvenido. Logueado correctamente."
            );
          } else {
            await logout()
            notification(
                "WalletNotRegistered",
                "error",
                "La sesión expiró"
            );
          }

        } else {
          notification(
            "WalletNotRegistered",
            "error",
            "La wallet no se encuentra registrada."
          );
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }, [validateAuditorLogin, notification]);

  useEffect(() => {
    if (!provider) {
      if (web3Modal.cachedProvider) {
        loginWithWeb3Modal();
      }
    }
  }, [loginWithWeb3Modal, provider]);

  return (
    <AuthContext.Provider
      value={{
        user,
        loginWithWeb3Modal,
        logout,
        provider,
        signer,
        loading,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
export default AuthProvider;
