import axios from "../../services/axios";
import { useState } from "react";

export interface Quote {
  _id: string;
  name: string;
  quote: number;
  icon: string;
}

export interface CreateEditQuoteData {
  _id?: string;
  name?: string;
  quote?: number;
  icon?: string;
  updatedIcon?: File;
}

async function getBase64(file: File) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      resolve(reader.result);
    };
    reader.onerror = reject;
  });
}

const useQuotes = () => {
  const [quotes, setQuotes] = useState<Quote[]>([]);
  const updateQuotes = async () => {
    const result = await axios.get(`/quotes`);
    setQuotes(result.data);
  };

  const createEditQuote = async (data: CreateEditQuoteData) => {
    const body: any = {
      name: data.name,
      quote: data.quote,
    };

    if (data._id) {
      body._id = data._id;
    }

    if (data.updatedIcon) {
      body.icon = await getBase64(data.updatedIcon);
    }

    // console.log(body);
    if(body._id) {
      await axios.patch(`/quotes`, body);
    } else {
      await axios.post(`/quotes`, body);
    }
    updateQuotes();
  };

  return {
    createEditQuote,
    updateQuotes,
    quotes,
  };
};

export default useQuotes;
