import React, {ChangeEvent, useEffect, useState} from "react";
import useNftRequests from "./useNftRequests";
import {
    BtnAccept,
    BtnDisabled,
    Container,
    FiltrarBy,
    Option,
    SubTitle,
    Title,
} from "./requestsStyles";
import {NFTRequestsChild} from "../../components/NFTRequestsChild";
import Loader from "../../components/Loader/Loader";
import {ContainerPagination} from "../../components/Users/userStyles";
import Pagination from "@mui/material/Pagination";
import {
    Button,
    Dialog, DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    Stack,
    TextField
} from "@mui/material";
import {NftRequestProps} from "./types";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import {nftRequestApi} from "../../services/api";
import {ModifyRequestModal} from "./modal/ModifyRequestModal";

type SelectedRequest = {
    request: NftRequestProps;
    canApprove: boolean;
};

const RejectRequestDialog = ({request, onCancel, onComplete, onLoading}: {
    request: NftRequestProps,
    onLoading: (isLoading: boolean) => void,
    onCancel: () => void,
    onComplete: () => void
}) => {
    const [reason, setReason] = useState<string>()
    const [isLoading, setIsLoading] = useState(false);

    const {
        rejectRequest,
    } = useNftRequests();

    const _rejectRequest = async () => {
        onLoading(true);
        setIsLoading(true)

        await rejectRequest(request, reason ?? '').finally(() => {
            onLoading(false);
            setIsLoading(false);
        });

        onComplete()
    };

    useEffect(() => {
        setReason(request.reject_reason)
    }, [request]);

    return <Dialog
        open={!!request}
        fullWidth
        maxWidth="md"
        onClose={onCancel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
    >
        <DialogTitle id="alert-dialog-title">
            {"Agregar información sobre el rechazo"}
        </DialogTitle>
        <DialogContent dividers>
            <Stack sx={{mt: 2}} spacing={4}>
                <TextField
                    color="secondary"
                    label="Razón"
                    multiline
                    rows={4}
                    value={reason}
                    onChange={(e) => setReason(e.target.value)}
                    defaultValue=""
                />
            </Stack>
        </DialogContent>
        <DialogActions>
            {isLoading ? <Loader/> : <>
                <Button
                    onClick={onCancel}
                    variant="contained"
                    color="error"
                    autoFocus
                    disabled={isLoading}
                >
                    Cancelar
                </Button>
                <Button
                    onClick={() => _rejectRequest()}
                    variant="contained"
                    color="success"
                    disabled={isLoading}
                >
                    Enviar
                </Button>
            </>}

        </DialogActions>
    </Dialog>


}

const NFTRequests = () => {
    const {
        user,
        nftRequests,
        nftRequestsHarvested,
        nftRequestsNotHarvested,
        getNFTRequests,
        page,
        setPage,
        pageCount,
        loading,
        filter,
        setFilter,
        search,
        setSearch,
        updateRequest,
        processRequest,
        approveRequest,
        rejectRequest,
        pdfBase64,
    } = useNftRequests();

    const [isLoading, setIsLoading] = useState(false);
    const [requests, setRequests] = useState<NftRequestProps[]>([]);
    const [requestToReject, setRequestToReject] = useState<NftRequestProps>();
    const [harvestFilter, setHarvestFilter] = useState<string | undefined>();

    useEffect(() => {
        if (harvestFilter) {
            if (harvestFilter == "notHarvested") {
                setRequests(nftRequestsNotHarvested);
            } else if (harvestFilter == "harvested") {
                setRequests(nftRequestsHarvested);
            } else {
                setRequests(nftRequests);
            }
        } else {
            setRequests(nftRequests);
        }
    }, [
        nftRequests,
        harvestFilter,
        nftRequestsNotHarvested,
        nftRequestsHarvested,
    ]);

    useEffect(() => {
        if (user && !user.isOwner) {
            setHarvestFilter("harvested");
        }
    }, [user]);

    const handleFilter = (e: React.ChangeEvent<HTMLInputElement>) => {
        setFilter(e.target.value);
    };

    const handleHarvestedFilter = (e: React.ChangeEvent<HTMLInputElement>) => {
        setHarvestFilter(e.target.value);
    };

    const [requestToModify, setRequestToModify] =
        useState<SelectedRequest | null>(null);

    const _handleModifyRequest = (request: NftRequestProps) =>
        setRequestToModify({
            request: request,
            canApprove: false,
        });

    const _handleApproveRequest = (request: NftRequestProps) => {
        setRequestToModify({
            request: request,
            canApprove: true,
        });
    };

    const _handleClose = () => setRequestToModify(null);

    const reloadData = async () => {
        setRequestToModify(null);
        setSearch("");
        setFilter(filter);
        await getNFTRequests();
    };

    const handleChange = (event: ChangeEvent<unknown>, _page: number) => {
        setPage(_page);
    };

    const handleKeyDown = async (event: any) => {
        if (event.key === "Enter") {
            await getNFTRequests();
        }
    };

    const _approveRequest = async (request: NftRequestProps) => {
        setIsLoading(true);
        await approveRequest(request).finally(() => {
            setIsLoading(false);
        });
        setRequestToModify(null);
        setSearch("");
        setFilter(filter);
        await getNFTRequests();
    };

    const _updateRequest = async (request: NftRequestProps, status: string) => {
        setIsLoading(true);
        await updateRequest(request, status).finally(() => {
            setIsLoading(false);
        });
        setSearch("");
        setFilter(filter);
        await getNFTRequests();
    };

    const _processRequest = async (request: NftRequestProps) => {
        setIsLoading(true);
        await processRequest(request).finally(() => {
            setIsLoading(false);
        });
        setSearch("");
        setFilter(filter);
        await getNFTRequests();
    };

    const exportCsv = () => {
        setIsLoading(true);
        nftRequestApi
            .downloadCsv()
            .finally(() => {
                alert("Solicitudes descargadas.");
                setIsLoading(false);
            })
            .catch((error) => {
                console.log(error);
            });
    };

    useEffect(() => {
        let f: string = "";

        if (user) {
            if (!user.isOwner) {
                f = "process";
            } else {
                if (filter) {
                    f = filter;
                } else {
                    f = "pending";
                }
            }
        }

        setFilter(f);
        getNFTRequests();
    }, [user, page, filter]);

    return (
        <Container>
            {requestToReject ?
                <RejectRequestDialog request={requestToReject} onLoading={(value) => {
                    setIsLoading(value)
                }} onCancel={
                    () => setRequestToReject(undefined)
                } onComplete={async () => {
                    setSearch("");
                    setFilter(filter);
                    setRequestToReject(undefined)
                    await getNFTRequests();
                }}/>
                : null}

            <div style={{display: "flex", alignItems: "center"}}>
                <Title>Solicitudes certificados NFT</Title>
                {isLoading ? (
                    <BtnDisabled style={{height: "fit-content"}}>
                        Exportar CSV
                    </BtnDisabled>
                ) : (
                    <BtnAccept onClick={exportCsv} style={{height: "fit-content"}}>
                        Exportar CSV
                    </BtnAccept>
                )}
            </div>
            <Grid container spacing={2}>
                {user?.isOwner ? (
                    <Grid item xs={6}>
                        <FiltrarBy onChange={(e: any) => handleFilter(e)}>
                            {user ? (
                                user.isOwner ? (
                                    <>
                                        <Option value="pending">Pendientes</Option>
                                        <Option value="approved">Aprobados</Option>
                                        <Option value="approved_by_auditor">
                                            Aprobados por oráculo
                                        </Option>
                                        <Option value="rejected">Rechazados</Option>
                                        <Option value="review">Revision</Option>
                                        <Option value="process">En proceso</Option>
                                    </>
                                ) : (
                                    <>
                                        <Option value="process">En proceso</Option>
                                    </>
                                )
                            ) : (
                                <></>
                            )}
                        </FiltrarBy>
                    </Grid>
                ) : (
                    <Grid item xs={6}>
                        <FiltrarBy onChange={(e: any) => handleHarvestedFilter(e)}>
                            <Option value="harvested">Ciclo Completo</Option>
                            <Option value="notHarvested">Ciclo sin finalizar</Option>
                            {/*{user ? (*/}
                            {/*  user.isOwner ? (*/}
                            {/*    <>*/}
                            {/*      <Option value="pending">Pendientes</Option>*/}
                            {/*      <Option value="approved">Aprobados</Option>*/}
                            {/*      <Option value="approved_by_auditor">*/}
                            {/*        Aprobados por oráculo*/}
                            {/*      </Option>*/}
                            {/*      <Option value="rejected">Rechazados</Option>*/}
                            {/*      <Option value="review">Revision</Option>*/}
                            {/*      <Option value="process">En proceso</Option>*/}
                            {/*    </>*/}
                            {/*  ) : (*/}
                            {/*    <>*/}
                            {/*      <Option value="process">En proceso</Option>*/}
                            {/*    </>*/}
                            {/*  )*/}
                            {/*) : (*/}
                            {/*  <></>*/}
                            {/*)}*/}
                        </FiltrarBy>
                    </Grid>
                )}
                <Grid item xs={6}>
                    <FormControl
                        variant="standard"
                        sx={{m: 1, minWidth: 10, display: "flex"}}
                    >
                        <TextField
                            fullWidth={true}
                            label="Buscar por correo, DNI, address o nombre"
                            variant="standard"
                            value={search}
                            inputProps={{style: {color: "#fff"}}}
                            onChange={(e: any) => setSearch(e.target.value)}
                            onKeyDown={handleKeyDown}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton>
                                            <SearchIcon
                                                style={{color: "white"}}
                                                onClick={getNFTRequests}
                                            />
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                            sx={{
                                marginBottom: "20px",
                                maxWidth: "90%",
                                label: {
                                    color: "white",
                                },
                            }}
                        />
                    </FormControl>
                </Grid>
            </Grid>

            {loading || isLoading ? (
                <Loader/>
            ) : (
                <TableContainer
                    sx={{
                        backgroundColor: "transparent",
                    }}
                    component={Paper}
                >
                    <Table>
                        <TableHead>
                            <TableRow>
                                {filter === "approved" ? (
                                    <TableCell sx={{color: "white"}}>Certificado</TableCell>
                                ) : null}
                                <TableCell sx={{color: "white"}}>ID</TableCell>
                                <TableCell sx={{color: "white"}}>Usuario</TableCell>
                                <TableCell sx={{color: "white"}}>Nombre Usuario</TableCell>
                                <TableCell sx={{color: "white"}}>Nombre Propiedad</TableCell>
                                <TableCell sx={{color: "white"}}>Estado</TableCell>
                                <TableCell sx={{color: "white"}}>Cultivo</TableCell>
                                <TableCell sx={{color: "white"}}>Densidad</TableCell>
                                <TableCell sx={{color: "white"}}>Semilla</TableCell>
                                <TableCell sx={{color: "white"}}>Fecha alta</TableCell>
                                <TableCell sx={{color: "white"}}>
                                    Fecha fin ciclo agronómico
                                </TableCell>
                                <TableCell sx={{color: "white"}}>Area definida</TableCell>
                                <TableCell sx={{color: "white"}}>
                                    Ton. CO2 compensado
                                </TableCell>
                                <TableCell sx={{color: "white"}}>
                                    Cant. TCOE calculada
                                </TableCell>
                                {filter === "approved" ? (
                                    <TableCell sx={{color: "white"}}>
                                        Cant. TCOE asignados
                                    </TableCell>
                                ) : null}
                                <TableCell sx={{color: "white"}}>Imagen</TableCell>
                                <TableCell sx={{color: "white"}}>Mapa</TableCell>
                                <TableCell sx={{color: "white"}}></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {filter && nftRequests.length > 0
                                ? requests.map((request: NftRequestProps) => (
                                    <NFTRequestsChild
                                        key={request._id}
                                        request={request}
                                        approveRequest={_handleApproveRequest}
                                        updateRequest={_updateRequest}
                                        modifyRequest={_handleModifyRequest}
                                        processRequest={_processRequest}
                                        rejectRequest={setRequestToReject}
                                        filter={filter}
                                        pdfBase64={pdfBase64}
                                    />
                                ))
                                : null}
                        </TableBody>
                    </Table>
                    {!(nftRequests.length > 0) ? (
                        <SubTitle>No hay solicitudes</SubTitle>
                    ) : null}
                </TableContainer>
            )}
            <ContainerPagination>
                <Pagination
                    count={pageCount}
                    page={page}
                    onChange={handleChange}
                    color="primary"
                    variant="outlined"
                    shape="rounded"
                    sx={{
                        button: {
                            color: "white",
                        },
                    }}
                />
            </ContainerPagination>

            {requestToModify ? (
                <ModifyRequestModal
                    request={requestToModify.request}
                    canApprove={requestToModify.canApprove}
                    onClose={_handleClose}
                    onSaveAndApprove={(req) => reloadData()}
                    onApprove={(req) => reloadData()}
                    onSave={(req) => {
                        reloadData();
                    }}
                />
            ) : (
                <></>
            )}
        </Container>
    );
};

export default NFTRequests;
