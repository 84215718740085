import styled from "styled-components";
import theme from "../../theme";

export const AuditorsContainer = styled.div`
  background-color: ${theme.palette.secondary.card};
  padding: 30px;
  border-radius: 10px;
  box-shadow: -3px 9px 5px -6px rgba(0, 0, 0, 0.39);
  -webkit-box-shadow: -3px 9px 5px -6px rgba(0, 0, 0, 0.39);
  -moz-box-shadow: -3px 9px 5px -6px rgba(0, 0, 0, 0.39);
`;

export const Title = styled.h1`
  font-size: 30px;
  color: white;
`;

export const RegisterContainer = styled.div`
  display: flex;
`;

export const Input = styled.input`
  background-color: transparent;
  border-radius: 5px;
  border: 1px solid ${theme.palette.primary.main};
  color: ${theme.palette.primary.main};
  padding: 5px;
  margin-right: 10px;
  &:focus {
    outline: none;
  }
`;

export const Button = styled.button`
  border-radius: 5px;
  padding: 10px;
  border: none;
  background: rgb(3, 192, 220);
  background: linear-gradient(
    107deg,
    rgba(3, 192, 220, 1) 0%,
    rgba(109, 231, 79, 1) 100%
  );
  cursor: pointer;
  font-weight: bold;
`;

export const BtnContainer = styled.div`
  padding-top: 20px;
`;

export const Text = styled.h2`
  font-size: 15px;
  color: ${theme.palette.primary.main};
  text-align: flex-start;
  position: relative;
  right: 40px;
`;

export const AuditorsList = styled.div`
  border: 1px solid ${theme.palette.secondary.tabs};
  padding: 15px;
  margin-bottom: 15px;
  box-shadow: -3px 9px 5px -6px rgba(0, 0, 0, 0.24);
  -webkit-box-shadow: -3px 9px 5px -6px rgba(0, 0, 0, 0.24);
  -moz-box-shadow: -3px 9px 5px -6px rgba(0, 0, 0, 0.24);
`;

export const AuditorsUl = styled.ul`
  list-style: none;
  padding: 0;
`;

export const IndexLi = styled.li`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 5px;
  font-size: 10px;
  color: ${theme.palette.primary.main};
  padding: 10px;
  width: 10px;
  height: 10px;
  border-radius: 99px;
  background-color: ${theme.palette.secondary.tabs};
`;

export const AuditorsLi = styled.li`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;
  font-size: 15px;
  color: ${theme.palette.primary.main};
  border-bottom: 1px solid ${theme.palette.secondary.tabs};
  padding-bottom: 10px;
`;

export const NoAuditors = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  left: 40px;
`;

export const SpanCopy = styled.span`
  cursor: pointer;
`;

export const CopiedContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  font-size: 10px;
  z-index: 1;
  color: ${theme.palette.primary.main};
  background-color: ${theme.palette.secondary.tabs};
  border-radius: 5px;
  padding: 10px;
  width: 80%;
`;

export const ContainerDelete = styled.div`
  margin-top: 10px;
`;
