import axios from '../../axios/index';

const getNotifications = async (
  id: string,
): Promise<any[] | null> => {
  const res = await axios.get<[]>(`notification/${id}`);

  const notifications = res.data;
  return notifications;
};

const createNotification = async (
  notification: any,
): Promise<any | null> => {
  const res = await axios.post<any>(
    'notification/',
    notification,
  );

  const notificationData = res.data;
  return notificationData;
};

const updateNotification = async (
  id: string,
  update: {},
): Promise<any[] | null> => {
  const res = await axios.put<any[]>(
    `notification/update/${id}`,
    update,
  );

  const notification = res.data;
  return notification;
};

export default { getNotifications, createNotification, updateNotification };