import { useCallback } from "react";
import { notification } from "../api";

const useNotifications = () => {
  const createNotification = useCallback(
    async (_notification: any): Promise<void> => {
      await notification.createNotification({
        ..._notification,
        read: false,
      });
    },
    []
  );

  return {
    createNotification,
  };
};

export default useNotifications;
