import styled from "styled-components";
import theme from "../../../theme";

export const Card = styled.div`
    display: block;
    align-items: center;
    justify-content: flex-start;
    width: auto;
    height: 100%;
    border-radius: 10px;
    background-color: ${theme.palette.secondary.card};
    box-shadow: 1px 5px 5px 0px rgba(0, 0, 0, 0.23);
    -webkit-box-shadow: 1px 5px 5px 0px rgba(0, 0, 0, 0.23);
    -moz-box-shadow: 1px 5px 5px 0px rgba(0, 0, 0, 0.23);
    transition: all 0.3s ease-in-out;
    font-size: 5px;
    font-weight: bold;
    color: ${theme.palette.primary.main};
    word-break: break-all;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 15px;
`;

export const CardTitle = styled.h1`
    font-size: 13px;
    color: #fff3;
    text-align: center;
`;

export const CardInfo = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    padding: 10px;
`;

export const TextInfo = styled.h1`
    font-size: 15px;
    color: white;
    margin: 5px;
    text-align: center;
`;

export const TextAnalytics = styled.h1`
    font-size: 20px;
    color: white;
    margin: 5px;
    text-align: center;
`;


export const Button = styled.button`
    border-radius: 5px;
    padding: 5px;
    border: none;
    background: rgb(3, 192, 220);
    background: linear-gradient(
            107deg,
            rgba(3, 192, 220, 1) 0%,
            rgba(109, 231, 79, 1) 100%
    );
    cursor: pointer;
    font-weight: bold;
`;

export const OwnerContainer = styled.div`
    display: block;
`;

export const ContainerBtns = styled.div`
    display: flex;
    justify-content: space-between;
`;


