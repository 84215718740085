import styled from "styled-components";
import theme from "../../theme";

export const Button = styled.button`
  border-radius: 5px;
  border: none;
  background: rgb(3, 192, 220);
  background: linear-gradient(
    107deg,
    rgba(3, 192, 220, 1) 0%,
    rgba(109, 231, 79, 1) 100%
  );
  cursor: pointer;
  font-weight: bold;
  height: 40px;
  justify-content: center;
  align-items: center;
`;

export const BtnContainer = styled.div``;

export const InputAndBtn = styled.div`
  display: flex;
`;

export const Input = styled.input`
  border-radius: 5px;
  padding: 10px;
  border: 1px solid rgb(3, 192, 220);
  background: none;
  margin-right: 10px;
  border-color: linear-gradient(
    107deg,
    rgba(3, 192, 220, 1) 0%,
    rgba(109, 231, 79, 1) 100%
  );
  width: 100%;
  height: 40px;
  color: rgb(3, 192, 220);
  font-weight: bold;
  focus-color: rgb(3, 192, 220);
  &:focus {
    outline: none;
  }
`;

export const Web3Container = styled.div`
  margin-top: 50px;
`;

export const LoginContainer = styled.div`
  margin-left: auto;
  margin-right: auto;
  border-radius: 10px;
  padding: 10px;
  width: 300px;
  height: 350px;
  border: 1px solid ${theme.palette.secondary.tabs};
  box-shadow: 1px 10px 5px -4px rgba(0, 0, 0, 0.17);
  -webkit-box-shadow: 1px 10px 5px -4px rgba(0, 0, 0, 0.17);
  -moz-box-shadow: 1px 10px 5px -4px rgba(0, 0, 0, 0.17);
  background: ${theme.palette.secondary.card};
`;

export const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  top: 20%;
`;

export const LogoContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-bottom: 1px solid ${theme.palette.secondary.tabs};
  padding: 10px;
`;

export const LogoImg = styled.img`
  width: 45px;
`;

export const TitleLogin = styled.h1`
  font-size: 24px;
  font-weight: bold;
  color: ${theme.palette.primary.main};
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0px;
  padding-bottom: 10px;
`;

export const SubTitleLogin = styled.h2`
  font-size: 14px;
  font-weight: bold;
  color: ${theme.palette.secondary.gray};
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0px 0px 10px 0px;
  padding-bottom: 10px;
`;

export const ElementsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
`;
