import useNftRequests from "../useNftRequests";
import {
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import { StyledTableCell, StyledTableRow } from "./styles";
import TableBody from "@mui/material/TableBody";
import Button from "@mui/material/Button";

import { useEffect, useState } from "react";
import { NftRequestProps, RequestLogs } from "../types";
import { mapChanges, mapRole } from "../../../typings/user";
import moment from "moment";

export type LogListProps = {
  request: NftRequestProps;
  open: boolean;
  onClose: () => void;
};
export const LogList = (props: LogListProps) => {
  const { getLogs, loading } = useNftRequests();
  const [logs, setLogs] = useState<RequestLogs[]>([]);

  useEffect(() => {
    getLogs(props.request._id).then((res) => {
      setLogs(res);
    });
  }, []);

  function splitAddress(address: string)  {
    return address.slice(0, 8) + "..." + address.slice(-8)
  }

  return (
    <Dialog open={props.open} fullWidth maxWidth="md">
      <DialogTitle>Lista de Cambios</DialogTitle>
      <DialogContent dividers>
        <TableContainer>
          <Table aria-label="customized table">
            <TableHead>
              <StyledTableRow>
                <StyledTableCell width="20%">Fecha</StyledTableCell>
                <StyledTableCell width="20%">Usuario</StyledTableCell>
                <StyledTableCell width="20%">Rol</StyledTableCell>
                <StyledTableCell>Cambios</StyledTableCell>
              </StyledTableRow>
            </TableHead>
            <TableBody>
              {logs?.map((row) => (
                <StyledTableRow key={row._id}>
                  <StyledTableCell component="th" scope="row">
                    {moment(row.createdAt).format("DD/MM/YYYY hh:mm")}
                  </StyledTableCell>
                  <StyledTableCell style={{ width: 100 }} align="left">
                    {row.address ? splitAddress(row.address) : splitAddress(props.request.user)}
                  </StyledTableCell>
                  <StyledTableCell style={{ width: 100 }} align="left">
                    <Chip
                      color="secondary"
                      label={mapRole(row.role ?? "")}
                    />
                  </StyledTableCell>
                  <StyledTableCell style={{ width: 100 }} align="left">
                    {mapChanges(row.changes ?? []).map((i) => {
                      return (
                        <Chip
                          key={`chip-${i}`}
                          sx={{ mr: 0.5 }}
                          color="info"
                          label={i}
                        />
                      );
                    })}
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>{" "}
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="secondary"
          autoFocus
          onClick={props.onClose}
        >
          Cerrar
        </Button>
      </DialogActions>
    </Dialog>
  );
};
