import { ConfigurationContainer, Title } from "./styles";
import {
  Button,
  IconButton,
  Paper,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";

import ModeEditIcon from "@mui/icons-material/ModeEdit";
import React, { useEffect, useState } from "react";
import useSustainableActivities from "./sustainableActivitiesWrapper";
import AddNewActivityForm from "./form";

interface Props {}

function createData(
  name: string,
  age: number,
  commision: number
  // calories: number,
  // fat: number,
  // carbs: number,
  // protein: number,
) {
  return { name, age, commision };
}

const rows = [
  createData("Agricultura", 30, 30),
  createData("Arquitectura", 159, 30),
  createData("Turismo", 159, 30),
  createData("Energía Renovable", 159, 30),
  createData("Eficiencia Energética", 159, 30),
  createData("Bonos Azules", 159, 30),
  createData("Biochars", 159, 30),
];

export const Configuration = (props: Props) => {
  const {
    activities,
    loading,
    getAllActivities,
    setCommision,
    setDuration,
    setActive,
  } = useSustainableActivities();

  const [dialogOpen, setDialogOpen] = useState(false);
  useEffect(() => {
    getAllActivities();
  }, []);

  return (
    <ConfigurationContainer>
      <Title>Configuración</Title>
      <Typography variant="h6" color="white">
        Tiempos Agronómicos
      </Typography>

      <Button
        color="secondary"
        variant="contained"
        sx={{ mt: 2 }}
        onClick={() => setDialogOpen(true)}
      >
        Agregar Actividad
      </Button>
      <AddNewActivityForm
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        onCreated={async () => {
          setDialogOpen(false);
          await getAllActivities();
        }}
      />

      <TableContainer component={Paper} sx={{ mt: 2 }}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Actividad Sostenible</TableCell>
              <TableCell align="center">Validez</TableCell>
              <TableCell align="center">Comisión</TableCell>
              <TableCell align="center">Activo</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {activities.map((row) => (
              <TableRow
                key={row.title}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {row.title}
                </TableCell>
                <TableCell align="center">
                  {row.duration} años{" "}
                  <IconButton
                    aria-label="edit duration"
                    onClick={async () => {
                      let durationStr = prompt("Ingrese la duración:");
                      let duration = Number(durationStr);
                      if (duration) {
                        await setDuration(row, duration);
                      }
                    }}
                  >
                    <ModeEditIcon />
                  </IconButton>
                </TableCell>
                <TableCell align="center">
                  {row.commission}%
                  <IconButton
                    aria-label="delete"
                    onClick={async () => {
                      let commisionStr = prompt("Ingrese la comisión:");
                      let commission = Number(commisionStr);
                      if (commission && commission < 100) {
                        await setCommision(row, commission);
                      }
                    }}
                  >
                    <ModeEditIcon />
                  </IconButton>
                </TableCell>
                <TableCell align="center">
                  <Switch
                    checked={row.active}
                    onChange={async (e) => {
                      await setActive(row, e.target.checked);
                    }}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </ConfigurationContainer>
  );
};
