import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
} from "@mui/material";
import { useState } from "react";
import { Bond } from "../types";
import { useBonds } from "../useBonds";

type Props = {
  request: Bond;
  open: boolean;
  onClose: () => void;
  onMoreInfoRequested: () => void;
  // onRequestMoreInfo: (text: string, reason: string) => void
};

enum InfoOption {
  photo = "Fotografia del cultivo",
  area = "Area del cultivo",
  seed = "Semilla utilizada",
  documentation = "Documentacion Requerida",
}

export const BondRequestMoreInfoDialog = (props: Props) => {
  const [selectedOption, setSelectedOption] = useState<string | undefined>();

  const [observation, setObservation] = useState<string | undefined>();

  const { requestMoreInfo } = useBonds();

  const _handleRequestMoreInfo = async (request: Bond) => {
    if (
      window.confirm("¿Estás seguro de solicitar más informacion al usuario?")
    ) {
      await requestMoreInfo(request._id, {
        status: "review",
        reason: selectedOption,
        observations: observation,
      });
      props.onMoreInfoRequested()
      props.onClose();
    }
  };

  const ALL_OPTIONS: InfoOption[] = [
    InfoOption.documentation,
    InfoOption.photo,
    InfoOption.area,
    InfoOption.seed,
  ];

  return (
    <Dialog
      open={props.open}
      fullWidth
      maxWidth="md"
      onClose={props.onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {"Solicitar más información al usuario"}
      </DialogTitle>
      <DialogContent dividers>
        <Stack sx={{ mt: 2 }} spacing={4}>
          <FormControl fullWidth>
            <InputLabel color="secondary">Motivo</InputLabel>
            <Select
              color="secondary"
              value={`${selectedOption}`}
              label="Motivo"
              onChange={(e) => setSelectedOption(e.target.value as string)}
            >
              {ALL_OPTIONS.map((value, index) => {
                return <MenuItem value={value}>{value}</MenuItem>;
              })}
            </Select>
          </FormControl>

          <TextField
            color="secondary"
            label="Notas"
            multiline
            rows={4}
            value={observation}
            onChange={(e) => setObservation(e.target.value)}
            defaultValue=""
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        {/* <Button onClick={handleClose} color="secondary">
              Fotografia del cultivo
            </Button>
            <Button onClick={handleClose} color="secondary" autoFocus>
              Información del Area
            </Button>
            <Button onClick={handleClose} color="secondary" autoFocus>
              Información acerca del cultivo
            </Button> */}
        <Button
          onClick={props.onClose}
          variant="contained"
          color="error"
          autoFocus
        >
          Cancelar
        </Button>
        <Button
          onClick={() => _handleRequestMoreInfo(props.request)}
          variant="contained"
          color="success"
        >
          Enviar
        </Button>
      </DialogActions>
    </Dialog>
  );
};
