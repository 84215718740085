import {useState} from "react";
import axios from "../../services/axios";

interface AnalyticsResponse {
    tokens_emited: number
    tokens_total: number
    tokens_available: number
    approved_requests: number
    approved_by_auditor_requests: number
    rejected_requests: number
    pending_requests: number
    reviewing_requests: number
    user_count: number
    validated_users: number
    pending_users: number
}

const useAnalytics = () => {
    const [analytics, setAnalytics] = useState<AnalyticsResponse>()

    const updateAnalytics = async () => {
        const result = await axios.get(`/analytics`);
        if(result.data) {
            setAnalytics(result.data)
        }
    }

    return {
        analytics,
        updateAnalytics
    }
}

export default useAnalytics;