import getCenter from "geolib/es/getCenter";

interface LatLng {
  latitude: number;
  longitude: number;
}

export const getCenterOfPolygon = (locations: LatLng[]): LatLng | null => {
  const center = getCenter(locations);
  return center || null;
};
