import { useCallback, useState } from "react";
import axios from "../../services/axios";

interface SustainableActivity {
  activity: string;
  title: string;
  duration: number;
  commission: number;
  active: boolean;
  createdAt: Date;
  updatedAt: Date;
}

const useSustainableActivities = () => {
  const [activities, setActivities] = useState<SustainableActivity[]>([]);
  const [loading, setLoading] = useState(true);

  const getAllActivities = useCallback(async () => {
    try {
      setLoading(true);
      const res = await axios.get(`/activities`);
      if (res.data) {
        setActivities(res.data);
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }, []);

  const createActivity = async (identifier: string, title: string, commission: number, duration: number) => {
    try {
      setLoading(true);
      const res = await axios.post(`/activities`, {
        activity: identifier,
        title: title,
        commission: commission,
        duration: duration,
        active: false,
      });

      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }


  const setCommision = async (
    activity: SustainableActivity,
    commission: number
  ) => {
    try {
      setLoading(true);
      let cpy = activity
      cpy.commission = commission;
      const res = await axios.patch(`/activities`, cpy);
      console.log(res);
      setLoading(false);

      await getAllActivities();
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const setDuration = async (
      activity: SustainableActivity,
      duration: number
  ) => {
    try {
      setLoading(true);
      let cpy = activity
      cpy.duration = duration;
      const res = await axios.patch(`/activities`, cpy);
      console.log(res);
      setLoading(false);

      await getAllActivities();
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const setActive = async (
      activity: SustainableActivity,
      active: boolean
  ) => {
    try {
      setLoading(true);
      let cpy = activity
      cpy.active = active;
      const res = await axios.patch(`/activities`, cpy);
      console.log(res);
      setLoading(false);

      await getAllActivities();
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };


  return {
    activities,
    loading,
    getAllActivities,
    setCommision,
    setActive,
    setDuration,
    createActivity,
  };
};

export default useSustainableActivities;
